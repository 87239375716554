export const optInMessage = (name: any) => {
  return `Olá!

  Nós da ${name} utilizamos da tecnologia BIUD para oferecer a melhor experiência possível para você.

  Preencha o cadastro para receber promoções personalizadas para o seu perfil e ofertas exclusivas.`;
};

export const optOutMessage = (name: any) => {
  return `Poxa cliente BIUD!

  Gostariamos que você continuasse como nosso cliente, você pode acompanhar várias coisas

  Tem certeza que deseja fazer isso?!!`;
};
