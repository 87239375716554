import React from "react";
import { internalLink } from "@services/linkHelper";

function ChooseBizGroupModalFooter() {
  return (
    <button
      id={`btn-choosenetwork-newnetwork`}
      className="business-group-card-button clickable-with-effect"
      onClick={() => (window.location.href = `${internalLink.BUSINESS_REGISTER}`)}
    >
      Criar empresa em uma nova rede
    </button>
  );
}

export default ChooseBizGroupModalFooter;
