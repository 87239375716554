import React, { useState } from "react";
import "./styles.css";
import OptionCard from "./OptionCard";
import { Popover } from "@mui/material";
import QuestionCircle from "../CustomIcons/Generics/QuestionCircle";

export default function Helper() {
  const [anchorEl, setAnchorEl] = useState(null);

  const options = [
    {
      description: "Canal BIUD Tech",
      icon: "content",
      link: "https://www.youtube.com/@biudtech",
      internal: false,
    },
    {
      description: " Treinamento da plataforma",
      icon: "content",
      link: "https://www.youtube.com/playlist?list=PLhoNTUXdNelb9EiWQcr5JPFVhJssjOec0",
      internal: false,
    },
    {
      description: "Pílulas BIUD",
      icon: "content",
      link: "https://www.youtube.com/playlist?list=PLhoNTUXdNelap3Iz0qFs13gYAiOuJTFRl",
      internal: false,
    },
    {
      description: "Status da plataforma",
      icon: "networkCheck",
      link: "https://biud.statuspage.io/",
      internal: false,
    },
    {
      description: "Tutoriais",
      icon: "tutorials",
      link: "/internal/tutorials",
      internal: true,
    },
    // {
    //   description: "BIUD + Sebrae",
    //   icon: "tutorials",
    //   link: "/internal/tutorials",
    //   internal: true,
    // },
  ];

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <QuestionCircle
        viewBox="0 0 14 14"
        onClick={handleClick}
        className={`question-circle${open ? " open-help-menu" : ""}`}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ marginTop: "12px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "4px 0" }}>
          {options.map((option: any, index) => (
            <OptionCard
              key={index}
              description={option.description}
              icon={option.icon}
              callback={option.callback}
              link={option.link}
              internal={option.internal}
            />
          ))}
        </div>
      </Popover>
    </>
  );
}
