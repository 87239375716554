import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import * as serviceWorker from "./serviceWorker";
import App from "./App";

// @ts-expect-error TS(2345): Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);

serviceWorker.unregister();
