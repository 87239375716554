import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Check } from "../../CustomIcons";
import ButtonClose from "../../Buttons/Close";
import BiudButton from "../../Buttons/BiudButton";

interface ISuccessModalContentProps {
  title?: string;
  onButtonClick?: () => void;
  buttonText?: string;
  secondarybtnOnClick?: () => void;
  secondaryBtnText?: string;
  autoMainBtnClick?: boolean;
  closeModal: () => void;
  containerProps?: object;
}

function SuccessModalContent({
  title = "",
  onButtonClick = () => "",
  buttonText = "Fechar",
  secondarybtnOnClick = () => "",
  secondaryBtnText = "",
  autoMainBtnClick = false,
  closeModal = () => "",
  containerProps = {},
}: ISuccessModalContentProps) {
  const [countdown, setCountdown] = useState(9);

  useEffect(() => {
    if (autoMainBtnClick) {
      const interval = setInterval(() => {
        setCountdown((old) => old - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (countdown == 0) {
      return onButtonClick();
    }
  }, [countdown]);

  return (
    <section className="sucess-modal-container anime-slit-in-hr" {...containerProps}>
      <header className="sucess-header-container">
        <div className="return-btn"></div>
        <div className="icon-container">
          <Check />
        </div>
        <div className="close-btn-container">
          <ButtonClose onClick={() => closeModal()} />
        </div>
      </header>
      <main className="sucess-main-container">
        <p className="title-msg">{title}</p>
      </main>
      <footer>
        {secondaryBtnText && (
          <BiudButton buttonType={`text-secondary`} size={`lg`} onClick={() => secondarybtnOnClick()} bold={false}>
            {secondaryBtnText}
          </BiudButton>
        )}
        <BiudButton
          buttonType={`primary`}
          size={`lg`}
          onClick={() => onButtonClick()}
          style={secondaryBtnText ? { width: "350px", margin: 0 } : { margin: 0 }}
          bold={false}
        >
          {`${buttonText} `}
          {autoMainBtnClick && <span className="countdown-color">{countdown}</span>}
        </BiudButton>
      </footer>
    </section>
  );
}

export default SuccessModalContent;
