import { PRODUCT } from "./ProductReducer";

export const setFilters = (filters: any) => (dispatch: any) => {
  dispatch({
    type: PRODUCT.FILTERS,
    result: filters,
  });
};

export const setProducts = (data: any) => (dispatch: any) => {
  dispatch({
    type: PRODUCT.INDEX,
    result: data,
  });
};

export const logout = () => (dispatch: any) => {
  dispatch({
    type: PRODUCT.LOGOUT,
  });
};
export const clear = () => (dispatch: any) => {
  dispatch({
    type: PRODUCT.CLEAR,
  });
};

const exported = {
  setFilters,
  setProducts,
  clear,
  logout,
};

export default exported;
