import React from "react";
import { isCS } from "@services/auth";
import { LinearProgress } from "@mui/material";
import ProtectedRoute from "@routes/ProtectedRoute";
import { CSDashLazy, CSBusinessPanelLazy } from "@pages";
import { Switch, Route, Redirect } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CSRoute({ component: Component, ...rest }: any) {
  const loadingLinear = (
    <LinearProgress sx={{ backgroundColor: "white", "& .MuiLinearProgress-bar": { backgroundColor: "#C4C4C4" } }} />
  );

  return (
    <Route
      {...rest}
      render={({ location, match: { url } }) => {
        return (
          <Switch>
            <ProtectedRoute
              path={`${url}/dashboard`}
              component={CSDashLazy}
              location={location}
              isPaymentOk={true}
              isEnabled={isCS()}
              isCs={true}
              suspenseFallback={loadingLinear}
            />
            <ProtectedRoute
              path={`${url}/businesspanel`}
              component={CSBusinessPanelLazy}
              location={location}
              isPaymentOk={true}
              isEnabled={isCS()}
              isCs={true}
              suspenseFallback={loadingLinear}
            />
            <Redirect from={`${url}/*`} to={`${url}/dashboard`} />
            <Redirect from={`${url}`} to={`${url}/dashboard`} />
          </Switch>
        );
      }}
    />
  );
}

export default CSRoute;
