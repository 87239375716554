import React from "react";
import { SvgIcon } from "@mui/material";

export default function Globe(props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M21.4102 8.64C21.4102 8.64 21.4102 8.64 21.4102 8.59C20.7056 6.66623 19.4271 5.00529 17.7477 3.83187C16.0683 2.65845 14.0689 2.02917 12.0202 2.02917C9.97145 2.02917 7.97213 2.65845 6.29271 3.83187C4.61328 5.00529 3.3348 6.66623 2.6302 8.59C2.6302 8.59 2.6302 8.59 2.6302 8.64C1.84332 10.8109 1.84332 13.1891 2.6302 15.36C2.6302 15.36 2.6302 15.36 2.6302 15.41C3.3348 17.3338 4.61328 18.9947 6.29271 20.1681C7.97213 21.3416 9.97145 21.9708 12.0202 21.9708C14.0689 21.9708 16.0683 21.3416 17.7477 20.1681C19.4271 18.9947 20.7056 17.3338 21.4102 15.41C21.4102 15.41 21.4102 15.41 21.4102 15.36C22.1971 13.1891 22.1971 10.8109 21.4102 8.64ZM4.2602 14C3.91342 12.6892 3.91342 11.3108 4.2602 10H6.1202C5.96023 11.3285 5.96023 12.6715 6.1202 14H4.2602ZM5.0802 16H6.4802C6.71491 16.8918 7.05041 17.7541 7.4802 18.57C6.49949 17.9019 5.67969 17.0241 5.0802 16ZM6.4802 8H5.0802C5.67107 6.97909 6.48039 6.10147 7.4502 5.43C7.03075 6.24725 6.70534 7.10942 6.4802 8ZM11.0002 19.7C9.77196 18.7987 8.90934 17.4852 8.5702 16H11.0002V19.7ZM11.0002 14H8.1402C7.95359 12.6732 7.95359 11.3268 8.1402 10H11.0002V14ZM11.0002 8H8.5702C8.90934 6.51477 9.77196 5.20132 11.0002 4.3V8ZM18.9202 8H17.5202C17.2855 7.10816 16.95 6.24594 16.5202 5.43C17.5009 6.09807 18.3207 6.97594 18.9202 8ZM13.0002 4.3C14.2284 5.20132 15.091 6.51477 15.4302 8H13.0002V4.3ZM13.0002 19.7V16H15.4302C15.091 17.4852 14.2284 18.7987 13.0002 19.7ZM15.8602 14H13.0002V10H15.8602C16.0468 11.3268 16.0468 12.6732 15.8602 14ZM16.5502 18.57C16.98 17.7541 17.3155 16.8918 17.5502 16H18.9502C18.3507 17.0241 17.5309 17.9019 16.5502 18.57ZM19.7402 14H17.8802C17.9615 13.3365 18.0016 12.6685 18.0002 12C18.0013 11.3315 17.9612 10.6636 17.8802 10H19.7402C20.087 11.3108 20.087 12.6892 19.7402 14Z" />
    </SvgIcon>
  );
}
