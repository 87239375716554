import storage from "../storage";

export interface IUserStore {
  user: {
    userType: string;
    subscription: {
      data: {
        name: string;
        readonly: boolean;
        plan: any;
        businesses: any[];
        activeSubscription: {
          id: number;
          status: string;
          createdAt: Date | null;
          finishedAt: Date | null;
          dueDay: number;
          expiresAt: Date | null;
          plan: {
            id: number;
            name: string;
          };
          maxMonthlySales: number;
          firstUnitPrice: number;
          additionalUnitPrice: number;
          leadsDataFranchise: number;
          perLeadAdditionalPrice: number;
        };
        invoices: any;
        activePaymentCards: any[];
        checkAgainAt: Date;
      };
      allowAccess: boolean;
      checkAgainAt: string;
    };
  };
}

const defaultState = {
  userType: "",
  subscription: storage.getItem("checkPayment") || {
    data: {},
    allowAccess: true,
    checkAgainAt: "1995-12-14T17:15:00.000Z",
  },
};

export const USER = {
  LOGIN: "user/login",
  LOGOUT: "user/logout",
  CHECK_PAYMENT: "user/check_payment",
};

export default function CurrentUserReducer(state = defaultState, action: any) {
  const nextState = { ...state };

  switch (action.type) {
    case USER.LOGIN:
      nextState.userType = action.payload;
      return nextState;
    case USER.LOGOUT:
      nextState.userType = "";
      return nextState;
    case USER.CHECK_PAYMENT:
      storage.setItem("checkPayment", action.payload);
      nextState.subscription = action.payload;
      return nextState;
    default:
      return nextState;
  }
}
