import { ApolloClient, InMemoryCache, from, ApolloLink, HttpLink } from "@apollo/client";
export { gql } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLErrors, NetworkError } from "@apollo/client/errors";
import { getGroup, getToken, isAdmin, logout } from "@/services/auth";

type TErrorLink = { graphQLErrors?: GraphQLErrors | undefined; networkError?: NetworkError | any };

const errorLink = onError(({ networkError }: TErrorLink) => {
  if (networkError?.statusCode === 401) logout();
});

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_API_URL,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getToken();
  const reqBody = operation?.query?.loc?.source?.body;

  const isMutation = reqBody?.includes("mutation") && !reqBody?.includes("LogBusinessInteractivity");
  if (isMutation && getGroup()?.readonly && !isAdmin()) throw new Error("modelbusiness");

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token || "."}`,
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      notifyOnNetworkStatusChange: true,
    },
  },
});
