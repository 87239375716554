import React from "react";
import "./styles.css";

function InsightCard({ text }: any) {
  return (
    <section className="insight-card-container">
      <span className="insight-text">{text}</span>
    </section>
  );
}

export default InsightCard;
