import moment from "moment";
import { companySelected } from "@services/auth";

const INITIAL_STATE = {
  data: {
    name: "",
    listFile: null,
    description: "",
    noiseControl: true,
    noiseControlDays: 5,
    dynamicProducts: false,
    startAt: moment().format(),
    endAt: moment().add(15, "days").format(),
    businessId: companySelected(),
    productIds: [],
    vehicles: [
      {
        type: "WHATSAPP", //WHATSAPP, EMAIL_MARKETING, FLAGS, OTHERS
        whatsappNumberId: 1,
        description: "description",
        files: [],
      },
    ],
    dispatchMoment: "IMMEDIATELY", //IMMEDIATELY, SCHEDULED, REPEAT
    dispatchRepeatFrequency: null, //DAILY, WEEKLY, FORTNIGHTLY, MONTHLY
    dispatchRepeatedlyAt: null,
    dynamicListId: null,
    scheduledTo: null,
    templateQueryFilterId: null,
    whatsappTemplates: [
      {
        integrationCode: "",
        headerVariables: [],
        bodyVariables: [],
        buttonUrlVariables: [],
      },
    ],
    customerFilter: null,
    productsLink: "not-associated", //dynamic, specific, not-associated
    selectedTemplate: {},
    selectedNumber: "",
    productsSelected: [],
    endAtText: "NEVER",
    analysisStartAt: moment().format(),
    analysisEndAt: moment().add(15, "days").format(),
    miaActivation: false,
    relatedBusinessesId: [],
    businessSelected: [],
    groupId: null,
    publicInfo: null,
    toEnrich: false,
    toAnalyze: true,
    customersAmount: 0,
    maxPeopleDispatch: 0,
    leadLoversMachineCode: null,
    isHotLead: false,
    hydratedBy: null,
    hotleadFilter: null,
  },
};

export const activationInitialState = { ...INITIAL_STATE.data };
export type TActivationReducer = typeof INITIAL_STATE;
export type TActivationFilters = typeof INITIAL_STATE.data;

export const ACTIVATION = {
  NAME: "activation/name",
  LISTFILE: "activation/listFile",
  DESCRIPTION: "activation/description",
  NOISE_CONTROL: "activation/noiseControl",
  NOISE_CONTROL_DAYS: "activation/noiseControlDays",
  DYNAMIC_PRODUCTS: "activation/dynamicProducts",
  START_AT: "activation/startAt",
  END_AT: "activation/endAt",
  BUSINESS_ID: "activation/businessId",
  PRODUCT_IDS: "activation/productIds",
  VEHICLES: "activation/vehicles",
  DISPATCH_MOMENT: "activation/dispatchMoment",
  DISPATCH_REPEAT_FREQUECY: "activation/dispatchRepeatFrequency",
  DISPATCH_REPEAT_AT: "activation/dispatchRepeatedlyAt",
  DISPATCH_ACTIVATION_FREQUENCY: "activation/activationFrequency",
  DYNAMIC_LIST_ID: "activation/dynamicListId",
  SCHEDULED_TO: "activation/scheduledTo",
  TEMPLATE_QUERY_FILTER_ID: "activation/templateQueryFilterId",
  WHATSAPP_TEMPLATES: "activation/whatsappTemplates",
  CUSTOMER_FILTER: "activation/customerFilter",
  PRODUCTS_LINK: "activation/productsLink",
  SELECTED_TEMPLATE: "activation/selectedTemplate",
  SELECTED_NUMBER: "activation/selectedNumber",
  PRODUCTS_SELECTED: "activation/productsSelected",
  END_AT_TEXT: "activation/endAtText",
  ANALYSIS_START_AT: "activation/analysisStartAt",
  ANALYSIS_END_AT: "activation/analysisEndAt",
  MIA_ACTIVATION: "activation/mia-activation",
  RELATED_BUSINESSES_ID: "activation/relatedBusinessesId ",
  BUSINESS_SELECTED: "activation/businessSelected",
  GROUP_ID: "activation/groupId",
  CLEAR: "activation/clear",
  PUBLIC_INFO: "activation/publicInfo",
  TO_ENRICH: "activation/toEnrich",
  TO_ANALYZE: "activation/toAnalyze",
  CUSTOMERS_AMOUNT: "activation/customersAmount",
  MAX_PEOPLE_DISPATCH: "activation/maxPeopleDispatch",
  LEADLOVERS_MACHINE_CODE: "activation/machineCode",
  INITIAL_STATE: "activation/initialState",
  IS_HOTLEAD: "activation/isHotLead",
  HIDRATED_BY: "activation/hydratedBy",
  HOTLEAD_FILTER: "activation/hotleadFilter",
  SENDER_BY: "activation/senderBy",
  MESSAGE_CONTENT: "activation/messageContent",
};

export default function ActivationReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case ACTIVATION.NAME:
      return { ...state, data: { ...state.data, name: action.payload } };
    case ACTIVATION.LISTFILE:
      return { ...state, data: { ...state.data, listfile: action.payload } };
    case ACTIVATION.DESCRIPTION:
      return { ...state, data: { ...state.data, description: action.payload } };
    case ACTIVATION.NOISE_CONTROL:
      return { ...state, data: { ...state.data, noiseControl: action.payload } };
    case ACTIVATION.NOISE_CONTROL_DAYS:
      return { ...state, data: { ...state.data, noiseControlDays: action.payload } };
    case ACTIVATION.DYNAMIC_PRODUCTS:
      return { ...state, data: { ...state.data, dynamicProducts: action.payload } };
    case ACTIVATION.START_AT:
      return { ...state, data: { ...state.data, startAt: action.payload } };
    case ACTIVATION.END_AT:
      return { ...state, data: { ...state.data, endAt: action.payload } };
    case ACTIVATION.BUSINESS_ID:
      return { ...state, data: { ...state.data, businessId: action.payload } };
    case ACTIVATION.PRODUCT_IDS:
      return { ...state, data: { ...state.data, productIds: action.payload } };
    case ACTIVATION.VEHICLES:
      return { ...state, data: { ...state.data, vehicles: action.payload } };
    case ACTIVATION.DISPATCH_MOMENT:
      return { ...state, data: { ...state.data, dispatchMoment: action.payload } };
    case ACTIVATION.DISPATCH_REPEAT_FREQUECY:
      return { ...state, data: { ...state.data, dispatchRepeatFrequency: action.payload } };
    case ACTIVATION.DISPATCH_REPEAT_AT:
      return { ...state, data: { ...state.data, dispatchRepeatedlyAt: action.payload } };
    case ACTIVATION.DISPATCH_ACTIVATION_FREQUENCY:
      return { ...state, data: { ...state.data, activationFrequency: action.payload } };
    case ACTIVATION.DYNAMIC_LIST_ID:
      return { ...state, data: { ...state.data, dynamicListId: action.payload } };
    case ACTIVATION.SCHEDULED_TO:
      return { ...state, data: { ...state.data, scheduledTo: action.payload } };
    case ACTIVATION.TEMPLATE_QUERY_FILTER_ID:
      return { ...state, data: { ...state.data, templateQueryFilterId: action.payload } };
    case ACTIVATION.WHATSAPP_TEMPLATES:
      return { ...state, data: { ...state.data, whatsappTemplates: action.payload } };
    case ACTIVATION.CUSTOMER_FILTER:
      return { ...state, data: { ...state.data, customerFilter: action.payload } };
    case ACTIVATION.PUBLIC_INFO:
      return { ...state, data: { ...state.data, publicInfo: action.payload } };
    case ACTIVATION.PRODUCTS_LINK:
      return { ...state, data: { ...state.data, productsLink: action.payload } };
    case ACTIVATION.SELECTED_TEMPLATE:
      return { ...state, data: { ...state.data, selectedTemplate: action.payload } };
    case ACTIVATION.SELECTED_NUMBER:
      return { ...state, data: { ...state.data, selectedNumber: action.payload } };
    case ACTIVATION.PRODUCTS_SELECTED:
      return { ...state, data: { ...state.data, productsSelected: action.payload } };
    case ACTIVATION.END_AT_TEXT:
      return { ...state, data: { ...state.data, endAtText: action.payload } };
    case ACTIVATION.ANALYSIS_START_AT:
      return { ...state, data: { ...state.data, analysisStartAt: action.payload } };
    case ACTIVATION.ANALYSIS_END_AT:
      return { ...state, data: { ...state.data, analysisEndAt: action.payload } };
    case ACTIVATION.MIA_ACTIVATION:
      return { ...state, data: { ...state.data, miaActivation: action.payload } };
    case ACTIVATION.RELATED_BUSINESSES_ID:
      return { ...state, data: { ...state.data, relatedBusinessesId: action.payload } };
    case ACTIVATION.GROUP_ID:
      return { ...state, data: { ...state.data, groupId: action.payload } };
    case ACTIVATION.BUSINESS_SELECTED:
      return { ...state, data: { ...state.data, businessSelected: action.payload } };
    case ACTIVATION.TO_ENRICH:
      return { ...state, data: { ...state.data, toEnrich: action.payload } };
    case ACTIVATION.TO_ANALYZE:
      return { ...state, data: { ...state.data, toAnalyze: action.payload } };
    case ACTIVATION.CUSTOMERS_AMOUNT:
      return { ...state, data: { ...state.data, customersAmount: action.payload } };
    case ACTIVATION.MAX_PEOPLE_DISPATCH:
      return { ...state, data: { ...state.data, maxPeopleDispatch: action.payload } };
    case ACTIVATION.LEADLOVERS_MACHINE_CODE:
      return { ...state, data: { ...state.data, leadLoversMachineCode: action.payload } };
    case ACTIVATION.IS_HOTLEAD:
      return { ...state, data: { ...state.data, isHotLead: action.payload } };
    case ACTIVATION.HIDRATED_BY:
      return { ...state, data: { ...state.data, hydratedBy: action.payload } };
    case ACTIVATION.HOTLEAD_FILTER:
      return { ...state, data: { ...state.data, hotleadFilter: action.payload } };
    case ACTIVATION.SENDER_BY:
      return { ...state, data: { ...state.data, senderBy: action.payload } };
    case ACTIVATION.MESSAGE_CONTENT:
      return { ...state, data: { ...state.data, messageContent: action.payload } };
    case ACTIVATION.INITIAL_STATE:
      return { data: action.payload };
    case ACTIVATION.CLEAR:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
