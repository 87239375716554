import { COMPANY } from "./CompanyReducer";

export const setCompaniesData = (companies: any) => (dispatch: any) => {
  dispatch({
    type: COMPANY.LIST,
    result: companies,
  });
};
export const setCompanyData = (company: any) => (dispatch: any) => {
  dispatch({
    type: COMPANY.INDEX,
    result: company,
  });
};
export const setBusinessPage = (businessPage: any) => (dispatch: any) => {
  dispatch({
    type: COMPANY.BUSINESS_PAGE,
    result: businessPage,
  });
};
export const setGoodbyePage = (goodbyePage: any) => (dispatch: any) => {
  dispatch({
    type: COMPANY.GOODBYE_PAGE,
    result: goodbyePage,
  });
};

export const setLoading = (status: any) => (dispatch: any) => {
  dispatch({
    type: COMPANY.LOADING,
    result: status,
  });
};

export const setReadOnly = (status: any) => (dispatch: any) => {
  dispatch({
    type: COMPANY.READ_ONLY,
    result: status,
  });
};

export const setIsChooseGroupBusinessRequired = (status: any) => (dispatch: any) => {
  dispatch({
    type: COMPANY.CHOOSE_GROUP_BUSINESS_REQUIRED,
    result: status,
  });
};

export const logout = () => (dispatch: any) => {
  dispatch({
    type: COMPANY.LOGOUT,
  });
};
export const clear = () => (dispatch: any) => {
  dispatch({
    type: COMPANY.CLEAR,
  });
};

const exported = {
  setCompaniesData,
  setCompanyData,
  setBusinessPage,
  setGoodbyePage,
  setLoading,
  setReadOnly,
  setIsChooseGroupBusinessRequired,
  clear,
  logout,
};

export default exported;
