import React from "react";
import "./styles.scss";
import Menu from "@components/Menu";
import Modal from "@components/Modal";
import Header from "@components/Header";

export interface IMainPageContainerModalProps {
  setModalContent: React.Dispatch<React.SetStateAction<JSX.Element>>;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setModalSize: React.Dispatch<React.SetStateAction<string>>;
  closeModal: () => void;
}

interface IMainPageContainer {
  children?: React.ReactNode;
  modalVisible?: boolean;
  modalContent?: React.ReactNode;
  modalSize?: string;
  modalOverflow?: boolean;
  type?: string;
  closeModal?: (param?: any, param2?: any) => void;
}

export default function MainPageContainer({
  children = <></>,
  modalVisible = false,
  modalContent = <></>,
  modalSize = "auto",
  modalOverflow = false,
  type = "modal-content",
  closeModal = () => "",
}: IMainPageContainer) {
  return (
    <>
      <div className="flex">
        <Menu />
        <div className="section-content-with-header">
          <Header />
          <main className="anime-fade-in">{children}</main>
        </div>
      </div>
      <Modal
        visible={modalVisible}
        content={modalContent}
        size={modalSize}
        clickOut={() => ""}
        type={type}
        overflow={modalOverflow}
      />
    </>
  );
}
