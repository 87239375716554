const INITIAL_STATE = {
  data: {
    loadingShopSelect: false,
  },
};

export type TNetworkDashboard = typeof INITIAL_STATE;
export const NETWORK_DASHBOARD = {
  LOADING_SHOP_SELECT: "network-dashboard/loading-shop-select",
  CLEAR: "network-dashboard/clear",
};

export default function NetworkDashboardReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case NETWORK_DASHBOARD.LOADING_SHOP_SELECT:
      return { ...state, data: { ...state.data, loadingShopSelect: action.payload } };
    case NETWORK_DASHBOARD.CLEAR:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
