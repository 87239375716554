import React from "react";
import "./styles.css";
import { companyIsSelected, getGroup } from "@/services/auth";

interface IModalProps {
  visible: boolean;
  content: React.ReactNode;
  size?: string;
  clickOut?: (param?: any) => void;
  overflow?: boolean;
  type?: string;
}

export default function Modal({
  visible,
  content,
  size = "normal",
  clickOut = () => "",
  overflow = false,
  type = "modal-content",
}: IModalProps) {
  window.onclick = (event) => {
    if (!getGroup() || !companyIsSelected) return;

    const target = event.target as Element;
    if (target.classList.contains("modal-flex")) {
      clickOut();
    }
  };

  return (
    <div className={`modal ${visible ? "modal-visible" : "modal-hidden"} flex`} style={{ padding: "0" }}>
      <div className="modal-flex flex flex-center">
        <div className={`${overflow ? "modal-content-overflow" : type} modal-${size}`}>{content}</div>
      </div>
    </div>
  );
}
