import React, { useState } from "react";
import { imgClose } from "@imgs";
import { getGroup, companyIsSelected } from "@services/auth";

interface IChooseBizGroupHeaderProps {
  onClose: () => void;
  userName?: string;
  setSearch: (param?: any, param2?: any, param3?: any) => void;
}

function ChooseBizGroupHeader({ onClose = () => "", userName = "", setSearch = () => "" }: IChooseBizGroupHeaderProps) {
  const [inputLoading, setInputLoading] = useState("");

  const clearStats = () => setInputLoading("");

  return (
    <>
      {!!getGroup() && companyIsSelected && (
        <img alt="Fechar" className="modal-close float-right" src={imgClose} onClick={() => onClose()} />
      )}
      <h3>
        Olá<span id={`text-choosenetwork-username`}>{userName ? ", " + userName : ""}</span>!
      </h3>
      <h3>Qual rede você quer administrar hoje?</h3>
      <input
        autoFocus
        id={`field-choosenetwork-search`}
        name="search"
        type="text"
        placeholder="Buscar..."
        className={`input-search input-search-item-list ${inputLoading}`}
        onChange={(e) => setSearch(e.target.value, 1000, clearStats)}
        onKeyDown={() => !inputLoading && setInputLoading("input-loading")}
      />
    </>
  );
}

export default ChooseBizGroupHeader;
