import React from "react";
import "./styles.scss";
import { LinearProgress } from "@mui/material";
import { MainPageContainer } from "@containers";

export default function RouteLoadingPage() {
  return (
    <MainPageContainer modalVisible={false} modalContent={<></>} modalSize="auto">
      <LinearProgress sx={{ backgroundColor: "white", "& .MuiLinearProgress-bar": { backgroundColor: "#C4C4C4" } }} />
    </MainPageContainer>
  );
}
