import React from "react";
import "./styles.css";
import XLSX from "xlsx";
import { useMutation } from "@apollo/client";
import BiudButton from "../Buttons/BiudButton";
import ActivationCardSkeleton from "./Skeleton";
import { companySelected, getGroup } from "@services/auth";
import { string, number, oneOfType, oneOf } from "prop-types";
import { getDateNow, phraseFirstLetterUpperCaseSpecial } from "@services/utils";
import { LOG_BUSINESS_INTERACTIVITY_MUTATION } from "@mutation/gql/user";

function ActivationCard({ qntClients, text, btnImg, btnText, btnType, loading, tendencyToBuy, title }: any) {
  const [logBusinessInteractivity] = useMutation(LOG_BUSINESS_INTERACTIVITY_MUTATION);
  async function downloadListLog() {
    const businessId = companySelected();
    const logData = {
      businessId,
      message: "Exportou para CSV na visao de Rede",
      section: window.location.pathname.replace("/internal/", ""),
    };
    logBusinessInteractivity({ variables: logData, fetchPolicy: "no-cache" });
  }

  async function downloadList(sheet: any) {
    downloadListLog();
    try {
      const dataAtual = getDateNow("DD-MM-YYYY");
      const horaAtual = getDateNow("HH:mm:ss");

      const formatedTitle = phraseFirstLetterUpperCaseSpecial(title);
      const fileName = `${
        getGroup()?.name
      } - Potenciais compradores de ${formatedTitle} - Extraído em ${dataAtual} às ${horaAtual}`;

      const ws = XLSX.utils.json_to_sheet(sheet);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "People");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } catch (err) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      console.log(err.message);
    }
  }

  if (loading) return <ActivationCardSkeleton />;
  return (
    <section className="activation-card-container">
      <span className="activation-text-content">
        <span className="qnt-text">{`${qntClients} clientes`} </span>
        {text}
      </span>
      <BiudButton
        buttonType={Number(qntClients) > 0 ? btnType : "disabled"}
        onClick={() => downloadList(tendencyToBuy)}
        disabled={Number(qntClients) <= 0}
        bold={true}
        size="sm"
        style={{ fontSize: 12 }}
      >
        {!!btnImg && <img src={btnImg} />}
        <span>{btnText}</span>
      </BiudButton>
    </section>
  );
}

ActivationCard.propTypes = {
  btnImg: string,
  btnText: string,
  text: string.isRequired,
  btnType: oneOf(["primary", "primary-outlined"]),
  qntClients: oneOfType([string, number]).isRequired,
};

ActivationCard.defaultProps = {
  btnImg: "",
  btnType: "primary",
  btnText: "Defina um texto",
};

export default ActivationCard;
