import React, { useState } from "react";
import "./styles.css";
import { createTheme } from "@mui/material";
import BiudButton from "@newcomponentes/Buttons/BiudButton";
import { Attention, Save } from "../../../../../../NewComponentes/CustomIcons";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";

function MenuContent({
  handleClose,
  copiedTemplate,
  setCopiedTemplate,
  anchorEl,
  setSelectedTemplate,
  setTemplateVariables,
}: any) {
  const [value, setValue] = useState("");
  const [error, setError] = useState(0);
  const [loading, setLoading] = useState(false);

  const theme = createTheme({
    typography: {
      body1: {
        color: "black",
      },
    },
  });

  const getTextInfo = (anchorEl: any) => {
    const textInfo = anchorEl["id"].split("-");
    const textRegion = textInfo[0];
    const textIndex = parseInt(textInfo[1]);
    const templateOriginalVarValue = parseInt(textInfo[2]);

    return { textRegion, textIndex, templateOriginalVarValue };
  };

  const changeTextVar = (textRegion: any, textIndex: any, value: any) => {
    const regex = /({{[^{}]+}})/;
    const originalText = copiedTemplate[textRegion]?.textContent?.split(regex);
    originalText[textIndex] = `{{${value}}}`;

    return originalText.join("");
  };

  const getVarStatusList = (newObj: any, textRegion: any) => {
    const regex = /({{[^{}]+}})/;
    const arrayText = newObj[textRegion];
    const vars = arrayText
      .map((text: any) => (regex.test(text) ? isNaN(text.slice(2, -2)) : 0))
      .filter((value: any) => value !== 0);

    return vars;
  };

  const submitVariable = async () => {
    if (!value) return setError(2);

    const { textRegion, textIndex, templateOriginalVarValue } = getTextInfo(anchorEl);
    const stringText = changeTextVar(textRegion, textIndex, value);

    const newObj = { ...copiedTemplate, [textRegion]: { textContent: stringText } };
    setTemplateVariables((oldObj: any) => ({
      ...oldObj,
      [textRegion]: {
        ...oldObj[textRegion],
        [textIndex]: {
          value,
          originalVarValue: oldObj[textRegion][textIndex]?.originalVarValue || templateOriginalVarValue,
        },
      },
    }));

    setCopiedTemplate(newObj);
    setSelectedTemplate(newObj);
    handleClose();
  };

  const handleErrorMessage = () => {
    switch (error) {
      case 1:
        return "Problema interno! ";
      case 2:
        return "Selecione uma opção: ";
      default:
        return "";
    }
  };

  return (
    <section className="temp-menu-container">
      <FormControl variant="standard" className="temp-form-control">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <RadioGroup
              value={value}
              name="radio-buttons-group"
              defaultValue="Última compra"
              className="radio-group-container"
              onChange={(e) => {
                setValue(e.target.value);
                if (error) setError(0);
              }}
              aria-labelledby="demo-radio-buttons-group-label"
            >
              {!!error && (
                <div className="temp-error-icon-container anime-slit-in-hr">
                  <Attention />
                  {handleErrorMessage()}{" "}
                </div>
              )}
              <FormControlLabel
                className={`form-control-container`}
                value="nome"
                control={<Radio size="small" />}
                label="Nome"
              />
              <FormControlLabel
                className={`form-control-container`}
                value="optout"
                control={<Radio size="small" />}
                label="Optout"
              />
            </RadioGroup>
          </ThemeProvider>
        </StyledEngineProvider>
      </FormControl>
      <BiudButton
        buttonType={!error ? "primary-outlined" : "tertiary-outlined"}
        size="md"
        bold={false}
        onClick={() => submitVariable()}
      >
        {!loading && <Save style={{ fontSize: "16px" }} />}
        {loading ? <CircularProgress size={20} /> : "Salvar"}
      </BiudButton>
    </section>
  );
}

export default React.memo(MenuContent);
