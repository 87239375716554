import React, { InputHTMLAttributes } from "react";
import "./styles.scss";
import InputMask from "react-input-mask";

interface IBiudMaskInputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: "phone" | "rg" | "cpf" | "cnpj" | "zipcode" | "card" | "cardCvc" | "cardValidity";
  id?: string;
  name?: string;
  className?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mask?: string;
  placeholder?: string;
  defaultValue?: string;
  labelBefore?: string;
  labelAfter?: string;
  isInvalid?: boolean;
  required?: boolean;
  disabled?: boolean;
}

function BiudMaskInput({
  type = undefined,
  id = "biud-mask-input",
  name = "",
  className = "",
  value = "",
  onChange = () => "",
  mask = "99.999.999/9999-99",
  placeholder = "",
  defaultValue = undefined,
  labelBefore = "",
  labelAfter = "",
  isInvalid = false,
  required = false,
  disabled = false,
  ...rest
}: IBiudMaskInputProps) {
  const handleMask = (type: any) => {
    const maskDict: { [key: string]: { mask: string; placeholder: string; label: string } } = {
      phone: { mask: "(99) 99999-9999", placeholder: placeholder || "(XX) XXXXX-XXXX", label: "Celular" },
      rg: { mask: "99.999.999-9", placeholder: placeholder || "XX.XXX.XXX-X", label: "RG" },
      cpf: { mask: "999.999.999-99", placeholder: placeholder || "XXX.XXX.XXX-XX", label: "CPF" },
      cnpj: { mask: "99.999.999/9999-99", placeholder: placeholder || "XX.XXX.XXX/XXXX-XX", label: "CNPJ" },
      zipcode: { mask: "99.999-999", placeholder: placeholder || "XX.XXX-XXX", label: "CEP" },
      card: {
        mask: "9999-9999-9999-9999",
        placeholder: placeholder || "XXXX-XXXX-XXXX-XXXX",
        label: "Número do Cartão",
      },
      cardCvc: { mask: "999", placeholder: placeholder || "CVC", label: "Código de Segurança" },
      cardValidity: { mask: "99/9999", placeholder: placeholder || "MM/AAAA", label: "Validade" },
    };

    return maskDict[type] || { mask, placeholder, label: labelBefore };
  };

  return (
    <label className={`biud-mask-input-label-container ${isInvalid ? "color-red-light" : ""}`}>
      {labelBefore || handleMask(type).label}
      <InputMask
        id={id}
        name={name}
        className={`biud-mask-input ${isInvalid ? "input-invalid" : ""} ${className}`}
        value={value}
        onChange={onChange}
        mask={handleMask(type).mask}
        placeholder={handleMask(type).placeholder}
        defaultValue={defaultValue}
        required={required}
        disabled={disabled}
        {...rest}
      />
      {labelAfter}
    </label>
  );
}

export default BiudMaskInput;
