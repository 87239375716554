import React from "react";
import "./styles.css";
import { imgBiudMiaLogo } from "@imgs";
import InsightCard from "./insightCard";
import { Skeleton } from "@mui/material";
import { object, arrayOf, bool } from "prop-types";

export default function MiaInsightsCard({ insightsList, loading }: any) {
  if (!loading && insightsList.length === 0)
    return <div className="anime-fade-in">Não foi possível encontrar insights</div>;

  return (
    <section className="mia-insights-card-container anime-fade-in">
      <header className="mia-header-container">
        <p className="mia-header-text">Insights da Mia</p>
        <img src={imgBiudMiaLogo} alt="logo biud" />
      </header>
      <main className="mia-cards-container">
        {!loading ? (
          insightsList.map((insight: any) => (
            <InsightCard key={crypto.randomUUID()} text={insight.text || insight.insight} />
          ))
        ) : (
          <Skeleton animation="wave" variant="rounded" width="100%" height={155} />
        )}
      </main>
    </section>
  );
}

MiaInsightsCard.propTypes = {
  insightsList: arrayOf(object).isRequired,
  loading: bool,
};

MiaInsightsCard.defaultProps = {
  loading: false,
};
