import React, { InputHTMLAttributes } from "react";
import "./styles.scss";

interface IBiudInput extends InputHTMLAttributes<HTMLInputElement> {
  labelBefore?: string;
  labelAfter?: string;
  className?: string;
  type?: string;
  isInvalid?: boolean;
  invalidMsg?: string;
}

function BiudInput({
  labelBefore = "",
  labelAfter = "",
  className = "",
  type = "text",
  isInvalid = false,
  invalidMsg = "",
  ...rest
}: IBiudInput) {
  return (
    <label className={`biud-input-label-container ${isInvalid ? "color-red" : ""}`}>
      {labelBefore}
      <input type={type} className={`biud-input ${isInvalid ? "input-invalid" : ""} ${className}`} {...rest} />
      {isInvalid && <span>{invalidMsg}</span>}
      {labelAfter}
    </label>
  );
}

export default BiudInput;
