const navMenuOptions = Object.freeze({
  NTDASHBOARD: "ntDashboard",
  NTCUSTOMERS: "ntCustomers",
  NTCONFIGURATIONS: "ntConfigurations",
  DASHBOARD: "dashboard",
  CUSTOMERS: "customers",
  INSIGHTS: "insights",
  SALES: "sales",
  CAMPAIGNS: "campaigns",
  SOCIALMEDIA: "socialmedia",
  ACTIVATIONS: "activations",
  OFFER: "offer",
  PRODUCTS: "products",
  GALERY: "galery",
  DATA: "data",
  CONFIGURATIONS: "configurations",
  FULL: "full",
});

export default navMenuOptions;
