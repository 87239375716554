import React, { useEffect, useState } from "react";
import Modal from "@components/Modal";
import { getCompany, getGroup } from "@services/auth";
import ChooseBusinessModalContent from "@components/ChooseBusinessModalContent";
import ChooseBusinessGroupModalContent from "@components/ChooseBusinessGroupModalContent";

function Welcome() {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const [modalSize, setModalSize] = useState("x-small");

  const [groupsQuantity, setGroupsQuantity] = useState(0);

  const closeModal = (callback = () => "") => {
    setModalVisible(false);
    setModalContent(<></>);
    callback();
  };

  const openChooseBusinessModal = () => {
    setModalContent(
      <ChooseBusinessModalContent
        onClose={closeModal}
        groupsQuantity={groupsQuantity}
        backBtnCallback={openChooseGroupModal}
        setModalContent={setModalContent}
        setModalVisible={setModalVisible}
        setModalSize={setModalSize}
        openChooseBusinessGroupModal={openChooseGroupModal}
      />
    );
    setModalVisible(true);
    setModalSize("x-small");
  };

  const openChooseGroupModal = () => {
    setModalContent(
      <ChooseBusinessGroupModalContent
        onClose={closeModal}
        callBack={openChooseBusinessModal}
        setGroupsQuantity={setGroupsQuantity}
      />
    );
    setModalVisible(true);
    setModalSize("x-small");
  };

  const handleLogin = () => {
    const group = getGroup();
    if (!group) {
      openChooseGroupModal();
      return;
    }

    const business = getCompany();
    if (!business) {
      openChooseBusinessModal();
    }
  };

  useEffect(handleLogin, []);

  return (
    <div className="flex">
      <Modal visible={modalVisible} content={modalContent} size={modalSize} clickOut={() => ""} overflow={true} />
    </div>
  );
}

export default Welcome;
