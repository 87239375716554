const INITIAL_STATE = {
  data: {
    email: "",
    name: "",
    phone: "",
    password: "",
    businessName: "",
    businessCity: "",
    businessPosition: "",
    businessUnitsAmount: 1,
    businessSegment: undefined,
  },
};

export const REGISTER = {
  EMAIL: "register/email",
  NAME: "register/name",
  PHONE: "register/phone",
  PASSWORD: "register/password",
  BUSINESS_NAME: "register/businessName",
  BUSINESS_CITY: "register/businessCity",
  BUSINESS_POSITION: "register/businessPosition",
  BUSINESS_UNITS_AMOUNT: "register/businessUnitsAmount",
  BUSINESS_SEGMENT: "register/businessSegment",
  CLEAR: "register/clear",
};

export default function ActivationReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case REGISTER.EMAIL:
      return { ...state, data: { ...state.data, email: action.payload } };
    case REGISTER.NAME:
      return { ...state, data: { ...state.data, name: action.payload } };
    case REGISTER.PHONE:
      return { ...state, data: { ...state.data, phone: action.payload } };
    case REGISTER.PASSWORD:
      return { ...state, data: { ...state.data, password: action.payload } };
    case REGISTER.BUSINESS_NAME:
      return { ...state, data: { ...state.data, businessName: action.payload } };
    case REGISTER.BUSINESS_CITY:
      return { ...state, data: { ...state.data, businessCity: action.payload } };
    case REGISTER.BUSINESS_POSITION:
      return { ...state, data: { ...state.data, businessPosition: action.payload } };
    case REGISTER.BUSINESS_UNITS_AMOUNT:
      return { ...state, data: { ...state.data, businessUnitsAmount: action.payload } };
    case REGISTER.BUSINESS_SEGMENT:
      return { ...state, data: { ...state.data, businessSegment: action.payload } };
    case REGISTER.CLEAR:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
