import { gql } from "@/graphql/apollo";

export const INSIGHT_CUSTOMER_LIST_QUERY = gql`
  query InsightCustomerList(
    $businessIds: [Int!]!
    $insightId: Int!
    $endDate: DateTime!
    $minPurchases: Int!
    $minPercentageChange: Float
  ) {
    InsightCustomerList(
      insightId: $insightId
      businessIds: $businessIds
      endDate: $endDate
      minPurchases: $minPurchases
      minPercentageChange: $minPercentageChange
    ) {
      report
      customers {
        id
        name
        cpf
        totalOrdersEver
        dayCountSinceLastPurchase
        avgOrderValue365
        newCustomer
        lostCustomer
        almostLostCustomer
        topCustomer
        spenderCustomer
        loyalCustomer
        phone
        email
      }
    }
  }
`;

export const INSIGHT_PRODUCT_LIST_QUERY = gql`
  query InsightProductList($businessIds: [Int!]!, $insightId: Int!, $endDate: Date!, $productIds: [Int!]!) {
    InsightProductList(insightId: $insightId, businessIds: $businessIds, endDate: $endDate, productIds: $productIds) {
      insight
      productName
      quantity30
      productId
    }
  }
`;

export const INSIGHT_CUSTOMERS_FOR_PRODUCT_QUERY = gql`
  query InsightCustomersForProduct($businessIds: [Int!]!, $product: String!, $startDate: DateTime, $endDate: DateTime) {
    InsightCustomersForProduct(businessIds: $businessIds, product: $product, startDate: $startDate, endDate: $endDate) {
      id
      name
      phone
      email
      description
      cpf
    }
  }
`;

export const INSIGHT_REPORT_QUERY = gql`
  query InsightCustomerList(
    $businessIds: [Int!]!
    $insightId: Int!
    $endDate: DateTime!
    $minPurchases: Int!
    $minPercentageChange: Float
  ) {
    InsightCustomerList(
      insightId: $insightId
      businessIds: $businessIds
      endDate: $endDate
      minPurchases: $minPurchases
      minPercentageChange: $minPercentageChange
    ) {
      report
    }
  }
`;

export const INSIGHT_CUSTOMER_QUERY = gql`
  query InsightCustomers($businessIds: [Int!]!, $customerId: Int!) {
    InsightCustomers(businessIds: $businessIds, customerId: $customerId, lookback: 365) {
      text
    }
  }
`;
