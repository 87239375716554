import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { isGsGroupId } from "@/services/utils";
import { LinearProgress } from "@mui/material";
import ProtectedRoute from "@routes/ProtectedRoute";
import { ICompanyStore } from "@store/Company/CompanyReducer";
import { IUserStore } from "@store/CurrentUser/CurrentUserReducer";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";
import RouteLoadingPage from "@pages/RouteLoadingPage/RouteLoadingPage";
import { getGroup, isAdmin, isAuthenticated, isCustomerService, isManager, isOwner, isWorker } from "@services/auth";
import {
  LoadingPage,
  NetworkDashClientLazy,
  NetworkProductsLazy,
  NetworkAnalyticsLazy,
  NetworkConfigurationLazy,
  NetworkCustomersLazy,
  NetworkFindoutLazy,
  NetworkInsightsLazy,
  NetworkPerceptionLazy,
  NetworkPublicsLazy,
  NetworkPluginsLazy,
  NetworkReportsLazy,
  NetworkSalesLazy,
  NetworkSalesListLazy,
  NetworkHotLeadsLazy,
  NetworkIntegrationsLazy,
  GeneralShoppingLazy,
  GeneralShoppingLiteLazy,
  GeneralShoppingWifiLazy,
  LeadsAutoLazy,
  ActivationsLazy,
  EnrichmentLazy,
  WhatsAppWebLazy,
  NetworkDashboardLazy,
  HomeLightLazy,
} from "@pages";

interface IPrivateRouteProps extends RouteProps {
  component?: React.FC<any>;
  path: string;
}

function PrivateRoute({ ...props }: IPrivateRouteProps) {
  const groupSubscriptionInfo = useSelector((store: IUserStore) => store.user.subscription);
  const loadingGlobal = useSelector((store: ICompanyStore) => store.company.loading);

  const paymentOK = groupSubscriptionInfo.allowAccess;
  const enterprise = groupSubscriptionInfo.data.activeSubscription?.plan.id === 3;

  const isGsGroup = isGsGroupId() || false;
  const groupId = getGroup()?.id || 0;
  const isAdm = isAdmin();
  const isDefaultUser = isOwner() || isAdm || isManager() || isWorker() || isCustomerService();

  const loadingLinear = (
    <LinearProgress sx={{ backgroundColor: "white", "& .MuiLinearProgress-bar": { backgroundColor: "#C4C4C4" } }} />
  );

  return (
    <Suspense fallback={<RouteLoadingPage />}>
      <Route
        {...props}
        render={({ location, match: { url } }) => {
          if (!isAuthenticated || !enterprise) {
            return <Redirect from="*" to="/login" />;
          }

          if (loadingGlobal) {
            return <Route path={`${url}/*`} component={LoadingPage} />;
          }

          return (
            <Switch>
              <ProtectedRoute
                path={`${url}/home`}
                component={HomeLightLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser}
              />
              <ProtectedRoute
                path={`${url}/dashboard`}
                component={NetworkDashboardLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/customers-dashboard`}
                component={NetworkDashClientLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/insights`}
                component={NetworkInsightsLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/configurations`}
                component={NetworkConfigurationLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/customers-list`}
                component={NetworkCustomersLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/reports`}
                component={NetworkReportsLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/products`}
                component={NetworkProductsLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/sales-dashboard`}
                component={NetworkSalesLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/sales-list`}
                component={NetworkSalesListLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/findout`}
                component={NetworkFindoutLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/perception`}
                component={NetworkPerceptionLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/publics`}
                component={NetworkPublicsLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/activations`}
                component={ActivationsLazy}
                exact
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/plugins`}
                component={NetworkPluginsLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/whatsappweb`}
                component={WhatsAppWebLazy}
                suspenseFallback={loadingLinear}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/analytics`}
                component={NetworkAnalyticsLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/enrichment`}
                component={EnrichmentLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/integrations`}
                component={NetworkIntegrationsLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise}
              />
              <ProtectedRoute
                path={`${url}/general-dashboard`}
                component={GeneralShoppingLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise && isGsGroup}
              />
              <ProtectedRoute
                path={`${url}/general-lite`}
                component={GeneralShoppingLiteLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise && isGsGroup}
              />
              <ProtectedRoute
                path={`${url}/general-wifi`}
                component={GeneralShoppingWifiLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise && isGsGroup}
              />
              <ProtectedRoute
                path={`${url}/hotleads`}
                component={NetworkHotLeadsLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise && isGsGroup}
              />
              <ProtectedRoute
                path={`${url}/leads`}
                component={LeadsAutoLazy}
                location={location}
                isPaymentOk={paymentOK}
                isEnabled={isDefaultUser && enterprise && groupId === 3189}
              />

              <Redirect from={`${url}/*`} to={`${url}/dashboard`} />
              <Redirect from={`${url}`} to={`${url}/dashboard`} />
            </Switch>
          );
        }}
      />
    </Suspense>
  );
}

export default PrivateRoute;
