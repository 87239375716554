import React, { Suspense } from "react";
import { isAuthenticated } from "@services/auth";
import { Route, Redirect } from "react-router-dom";

interface IPublicRouteProps {
  component: React.FC<any>;
  path: string;
  isAuthenticatedRedirectEnabled?: boolean;
  suspenseFallback?: React.ReactNode;
}

function PublicRoute({
  component: Component,
  isAuthenticatedRedirectEnabled = true,
  suspenseFallback = null,
  ...rest
}: IPublicRouteProps) {
  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          if (isAuthenticated && isAuthenticatedRedirectEnabled) {
            return <Redirect to="/internal/home" />;
          }

          if (suspenseFallback) {
            return (
              <Suspense fallback={suspenseFallback}>
                <Component {...props} />
              </Suspense>
            );
          }

          return <Component {...props} />;
        }}
      />
    </>
  );
}

export default PublicRoute;
