import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function MemberKit(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.60802 18.5L20.692 18.5C22.377 18.5 23.3787 16.6206 22.4378 15.2244L17.184 7.42825C16.35 6.19058 14.5264 6.19058 13.6924 7.42825L12.15 9.71697L10.6076 7.42825C9.77359 6.19058 7.95004 6.19058 7.11598 7.42825L1.86219 15.2244C0.921301 16.6206 1.92298 18.5 3.60802 18.5ZM8.48811 17.3L20.692 17.3C21.415 17.3 21.8448 16.4936 21.4411 15.8945L16.1873 8.0983C15.8294 7.56723 15.047 7.56723 14.6891 8.0983L8.48811 17.3Z"
      ></path>
    </SvgIcon>
  );
}
