import { SvgIcon } from "@mui/material";

function Save(props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M20.71 9.29L14.71 3.29C14.6178 3.20005 14.5092 3.12874 14.39 3.08C14.266 3.02962 14.1338 3.0025 14 3H6C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6V18C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21H18C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18V10C21.0008 9.86839 20.9755 9.73793 20.9258 9.61609C20.876 9.49426 20.8027 9.38344 20.71 9.29ZM9 5H13V7H9V5ZM15 19H9V16C9 15.7348 9.10536 15.4804 9.29289 15.2929C9.48043 15.1054 9.73478 15 10 15H14C14.2652 15 14.5196 15.1054 14.7071 15.2929C14.8946 15.4804 15 15.7348 15 16V19ZM19 18C19 18.2652 18.8946 18.5196 18.7071 18.7071C18.5196 18.8946 18.2652 19 18 19H17V16C17 15.2044 16.6839 14.4413 16.1213 13.8787C15.5587 13.3161 14.7956 13 14 13H10C9.20435 13 8.44129 13.3161 7.87868 13.8787C7.31607 14.4413 7 15.2044 7 16V19H6C5.73478 19 5.48043 18.8946 5.29289 18.7071C5.10536 18.5196 5 18.2652 5 18V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H7V8C7 8.26522 7.10536 8.51957 7.29289 8.70711C7.48043 8.89464 7.73478 9 8 9H14C14.2652 9 14.5196 8.89464 14.7071 8.70711C14.8946 8.51957 15 8.26522 15 8V6.41L19 10.41V18Z" />
    </SvgIcon>
  );
}

export default Save;
