const defaultState = {
  data: null,
  filters: null,
};
export const PRODUCT = {
  INDEX: "product/index",
  FILTERS: "product/filters",
  CLEAR: "product/clear",
  LOGOUT: "product/logout",
};
export default function ProductReducer(state = defaultState, action: any) {
  const nextState = { ...state };

  switch (action.type) {
    case PRODUCT.INDEX:
      // @ts-expect-error TS(2339): Property 'index' does not exist on type '{ data: n... Remove this comment to see the full error message
      nextState.index = action.result;
      return nextState;
    case PRODUCT.FILTERS:
      nextState.filters = action.result;
      return nextState;
    default:
      return nextState;
  }
}
