import React, { useState } from "react";
import "./styles.css";
import Modal from "../Modal";
import symbol from "@imgs/symbol.svg";
import { logout } from "@services/auth";
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import logoutImg from "@imgs/logoutNew.svg";
import Helper from "../NewComponentes/Helper";
// @ts-ignore
import { createBrowserHistory } from "history";
import LogoutModalContent from "../LogoutModalContent";
import { getCurrentEnvironment } from "@services/utils";
import { logout as logoutRedux } from "../../store/store";
import InfoCircle from "@newcomponentes/CustomIcons/Generics/InfoCircle";

export const history = createBrowserHistory({ forceRefresh: true });

export default function Header({ isMenuEnabled = true }: { isMenuEnabled?: boolean }) {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);
  const [showMenu, setShowMenu] = useState(false);

  const handleLogout = () => {
    dispatch(logoutRedux());
    logout();
  };

  function logoutContent() {
    setModalContent(
      <LogoutModalContent
        title="Tem certeza que deseja sair do sistema?"
        closeModal={() => setVisible(false)}
        onButtonClickCancel={() => setVisible(false)}
        onButtonClickConfirm={handleLogout}
      />
    );
    setVisible(true);
  }

  function showTheMenu(show: boolean) {
    setShowMenu(show);
    const menu: any = document.querySelector(".menu");
    // @ts-ignore
    menu.style.display = show ? "inline" : "none";
  }

  const handleEnvironmentTooltip = () => {
    const envDict: { [key: string]: string } = {
      prod: "",
      hmg: "O ambiente de homologação é a validação final antes de ir para produção.",
      dev: "O ambiente de desenvolvimento pode conter bugs e funcionalidades incompletas.",
    };
    return envDict[import.meta.env.VITE_ENV];
  };

  return (
    <header>
      <nav className="Box">
        <div className="navLeft">
          <div className="flex">
            <img src={symbol} className="symbol" alt="BIUD" />
            {import.meta.env.VITE_ENV !== "prod" && (
              <div className="dev-env-text-msg">
                <span>{`Ambiente de ${getCurrentEnvironment()}`}</span>
                <Tooltip title={handleEnvironmentTooltip()} style={{ cursor: "help" }}>
                  <span className="tooltip-text-msg">
                    <InfoCircle style={{ width: 18, height: 18 }} viewBox="0 0 15 15" />
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div className="navRigth">
          <Helper />
          <img
            id="btn-header-logout"
            className="x-small-img logout-image"
            src={logoutImg}
            onClick={logoutContent}
            alt="Porta de saída"
            title="Sair"
          />
          {isMenuEnabled && (
            <div className="menu-responsive">
              <i
                className="fa fa-bars"
                onClick={() => {
                  showTheMenu(!showMenu);
                }}
              />
            </div>
          )}
        </div>
      </nav>
      <Modal visible={visible} content={modalContent} size="auto" />
    </header>
  );
}
