import React from "react";
import { SvgIcon } from "@mui/material";

function Mouse(props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9V15C5 16.8565 5.7375 18.637 7.05025 19.9497C8.36301 21.2625 10.1435 22 12 22C13.8565 22 15.637 21.2625 16.9497 19.9497C18.2625 18.637 19 16.8565 19 15V9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2V2ZM7 9C6.99976 7.84735 7.39778 6.73001 8.12669 5.8371C8.8556 4.94418 9.87064 4.33053 11 4.1V10H7V9ZM17 15C17 16.3261 16.4732 17.5979 15.5355 18.5355C14.5979 19.4732 13.3261 20 12 20C10.6739 20 9.40215 19.4732 8.46447 18.5355C7.52678 17.5979 7 16.3261 7 15V12H17V15ZM17 10H13V4.1C14.1294 4.33053 15.1444 4.94418 15.8733 5.8371C16.6022 6.73001 17.0002 7.84735 17 9V10Z" />
    </SvgIcon>
  );
}

export default Mouse;
