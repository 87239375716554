import { client, gql } from "../config";
import { FetchPolicy } from "apollo-boost";
import {
  INSIGHT_CUSTOMER_LIST_QUERY,
  INSIGHT_CUSTOMER_QUERY,
  INSIGHT_CUSTOMERS_FOR_PRODUCT_QUERY,
  INSIGHT_PRODUCT_LIST_QUERY,
} from "@query/gql/insight";

export async function getBusinessInsights(variables: any, fetchPolicy: FetchPolicy = "no-cache") {
  const query = gql`
    query businessInsight($businessId: Int) {
      BusinessInsight(businessId: $businessId) {
        id
        title
        subtitle
        description
        video
        image
        type
      }
    }
  `;

  const businessInsight = await client.query({
    query,
    variables,
    fetchPolicy,
  });

  return await businessInsight.data;
}

export async function getInsightCampaigns(data: any) {
  const insightCampaignsQuery = gql`
    query InsightCampaigns(
      $businessIds: [Int!]!
      $maxDaysSinceLastPurchase: Int!
      $minPurchases: Int!
      $minAverageOrderValue: Float!
    ) {
      InsightCampaigns(
        businessIds: $businessIds
        maxDaysSinceLastPurchase: $maxDaysSinceLastPurchase
        minPurchases: $minPurchases
        minAverageOrderValue: $minAverageOrderValue
        lookback: 365
      ) {
        categoryId
        description
        total_customers
      }
    }
  `;

  const insightCampaigns = await client.query({
    query: insightCampaignsQuery,
    variables: data,
    fetchPolicy: "no-cache",
  });

  return await insightCampaigns.data;
}

export async function getInsightCampaignCustomers(data: any) {
  const insightCampaignCustomers = gql`
    query InsightCampaignCustomers(
      $categoryId: Int!
      $businessIds: [Int!]!
      $maxDaysSinceLastPurchase: Int!
      $minPurchases: Int!
      $minAverageOrderValue: Float!
      $lookback: Int!
    ) {
      InsightCampaignCustomers(
        categoryId: $categoryId
        businessIds: $businessIds
        maxDaysSinceLastPurchase: $maxDaysSinceLastPurchase
        minPurchases: $minPurchases
        minAverageOrderValue: $minAverageOrderValue
        lookback: $lookback
      ) {
        id
        name
        phone
        email
        description
        explain
      }
    }
  `;

  const insightCampaignsCustomers = await client.query({
    query: insightCampaignCustomers,
    variables: data,
    fetchPolicy: "no-cache",
  });

  return await insightCampaignsCustomers.data;
}

export async function getInsightCustomers(data: any) {
  const res = await client.query({
    query: INSIGHT_CUSTOMER_QUERY,
    variables: data,
    fetchPolicy: "no-cache",
  });

  return res.data;
}

export async function getInsightParamsSuggest(data: any) {
  const insightParamsSuggestQuery = gql`
    query InsightParamsSuggest($businessIds: [Int!]!) {
      InsightParamsSuggest(businessIds: $businessIds) {
        maxDaysSinceLastPurchase
        minPurchases
        minAvgOrderValue
      }
    }
  `;

  const insightParams = await client.query({
    query: insightParamsSuggestQuery,
    variables: data,
  });

  return await insightParams.data;
}

export async function getInsightCustomersForProduct(data: any, fetchPolicy: FetchPolicy = "cache-first") {
  const insightResult = await client.query({
    query: INSIGHT_CUSTOMERS_FOR_PRODUCT_QUERY,
    variables: data,
    errorPolicy: "all",
    fetchPolicy,
  });

  return await insightResult.data;
}

export async function getBuyersIdForProduct(data: any) {
  const insightCustomersForProduct = gql`
    query InsightCustomersForProduct(
      $businessIds: [Int!]!
      $product: String!
      $startDate: DateTime
      $endDate: DateTime
    ) {
      InsightCustomersForProduct(
        businessIds: $businessIds
        product: $product
        startDate: $startDate
        endDate: $endDate
      ) {
        id
      }
    }
  `;

  const insightResult = await client.query({
    query: insightCustomersForProduct,
    variables: data,
  });

  return await insightResult.data;
}

export async function getInsightCustomersList(data: any, fetchPolicy: FetchPolicy = "cache-first") {
  const insightResult = await client.query({
    query: INSIGHT_CUSTOMER_LIST_QUERY,
    variables: data,
    fetchPolicy,
  });

  return insightResult.data.InsightCustomerList;
}

export async function getProductInsight(data: any) {
  const insightResult = await client.query({
    query: INSIGHT_PRODUCT_LIST_QUERY,
    variables: data,
  });

  return insightResult.data.InsightProductList;
}

export async function getInsightsFromBusinessRanking(variables: any, fetchPolicy: FetchPolicy = "no-cache") {
  const query = gql`
    query businessInsight_TopLastRanking(
      $businessId1: Int
      $businessId2: Int
      $businessId3: Int
      $businessId4: Int
      $businessId5: Int
      $businessId6: Int
    ) {
      top1: BusinessInsight(businessId: $businessId1) {
        id
        title
        description
      }
      top2: BusinessInsight(businessId: $businessId2) {
        id
        title
        description
      }
      top3: BusinessInsight(businessId: $businessId3) {
        id
        title
        description
      }
      last1: BusinessInsight(businessId: $businessId4) {
        id
        title
        description
      }
      last2: BusinessInsight(businessId: $businessId5) {
        id
        title
        description
      }
      last3: BusinessInsight(businessId: $businessId6) {
        id
        title
        description
      }
    }
  `;
  const res = await client.query({
    query,
    variables,
    fetchPolicy,
  });

  return res.data;
}
