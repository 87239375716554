import { SvgIcon } from "@mui/material";

function Attention(props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M12 14C11.7528 14 11.5111 14.0733 11.3055 14.2107C11.1 14.348 10.9398 14.5432 10.8451 14.7716C10.7505 15.0001 10.7258 15.2514 10.774 15.4939C10.8222 15.7363 10.9413 15.9591 11.1161 16.1339C11.2909 16.3087 11.5137 16.4277 11.7561 16.476C11.9986 16.5242 12.2499 16.4995 12.4784 16.4049C12.7068 16.3102 12.902 16.15 13.0393 15.9445C13.1767 15.7389 13.25 15.4972 13.25 15.25C13.25 14.9185 13.1183 14.6005 12.8839 14.3661C12.6495 14.1317 12.3315 14 12 14ZM12 12.5C12.2652 12.5 12.5196 12.3946 12.7071 12.2071C12.8946 12.0196 13 11.7652 13 11.5V8.5C13 8.23478 12.8946 7.98043 12.7071 7.79289C12.5196 7.60536 12.2652 7.5 12 7.5C11.7348 7.5 11.4804 7.60536 11.2929 7.79289C11.1054 7.98043 11 8.23478 11 8.5V11.5C11 11.7652 11.1054 12.0196 11.2929 12.2071C11.4804 12.3946 11.7348 12.5 12 12.5ZM12 2C10.0222 2 8.08879 2.58649 6.44429 3.6853C4.7998 4.78412 3.51808 6.3459 2.7612 8.17317C2.00433 10.0004 1.80629 12.0111 2.19214 13.9509C2.578 15.8907 3.5304 17.6725 4.92893 19.0711C6.32745 20.4696 8.10928 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.997 9.34874 20.9425 6.80691 19.0678 4.93219C17.1931 3.05746 14.6513 2.00295 12 2ZM12 20C10.4177 20 8.87103 19.5308 7.55543 18.6518C6.23984 17.7727 5.21446 16.5233 4.60896 15.0615C4.00346 13.5997 3.84503 11.9911 4.15371 10.4393C4.4624 8.88743 5.22432 7.46197 6.34314 6.34315C7.46196 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C19.9976 14.121 19.1539 16.1544 17.6542 17.6542C16.1544 19.1539 14.121 19.9976 12 20Z" />
    </SvgIcon>
  );
}

export default Attention;
