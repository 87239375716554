import React, { InputHTMLAttributes } from "react";
import "./styles.scss";

interface IBiudInputDate extends InputHTMLAttributes<HTMLInputElement> {
  labelBefore?: string;
  labelAfter?: string;
  className?: string;
  isInvalid: boolean;
  invalidMsg: string;
}

function BiudInputDate({
  labelBefore = "",
  labelAfter = "",
  className = "",
  isInvalid = false,
  invalidMsg = "",
  ...rest
}: IBiudInputDate) {
  return (
    <section className="select-date-input-container">
      <label className={`biud-input-label-container ${isInvalid ? "color-red" : ""}`}>
        {labelBefore}
        <input
          type="date"
          className={`biud-input ${isInvalid ? "input-invalid" : ""} ${className}`}
          onClick={(e) => {
            try {
              e.preventDefault();
              e.currentTarget.showPicker();
            } catch (err: any) {
              console.log(err.message);
            }
          }}
          onKeyDown={(e) => {
            e.preventDefault();
            e.currentTarget.showPicker();
          }}
          {...rest}
        />
        {isInvalid && <span>{invalidMsg}</span>}
        {labelAfter}
      </label>
    </section>
  );
}

BiudInputDate.defaultProps = {
  labelBefore: "",
  labelAfter: "",
  className: "",
  isInvalid: false,
  invalidMsg: "",
};

export default BiudInputDate;
