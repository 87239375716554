const NumberExtension = function () {
  /**
   * Plugin for formatting numbers
   * Number.prototype.format(n, x, s, c)
   *
   * @param n: Decimal size, eg: 2
   * @param x: Thousands or blocks size, eg: 3
   * @param s: Delimiters of the thousands or blocks, eg: '.'
   * @param c: Decimal delimiter, eg: ','
   *
   * Usage: Ex1: new Number(10000).format(2, 3, '.', ',');
   *        Ex2: parseFloat(10000).format(2, 3, '.', ',');
   *        Ex3: parseInt(10000).format(2, 3, '.', ',');
   *
   * @see Another approach is String.mask
   */

  // @ts-expect-error TS(2339): Property 'format' does not exist on type 'Number'.
  if (!Number.prototype.format)
    Object.defineProperty(Number.prototype, "format", {
      value: function value(n: any, x: any, s: any, c: any) {
        if (n === void 0) {
          n = 2;
        }

        if (x === void 0) {
          x = 3;
        }

        if (s === void 0) {
          s = ".";
        }

        if (c === void 0) {
          c = ",";
        }

        const re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")";
        const num = this.toFixed(Math.max(0, ~~n));
        return (c ? num.replace(".", c) : num).replace(new RegExp(re, "g"), "$&" + (s || ","));
      },
    });

  /**
   * Plugin for formatting Brazilian Real numbers
   *
   * @param signed: Boolean true or false. If true or undefined, return que output number with 'R$' sign,
   * if false, returns formatted number only.
   * @param n: Decimal size, eg: 2
   * @param x: Thousands or blocks size, eg: 3
   * @param s: Delimiters of the thousands or blocks, eg: '.'
   * @param c: Decimal delimiter, eg: ','
   *
   * Usage: Ex1: new Number(10000).formatAsBRL();
   *        Ex2: Number(10000.32).formatAsBRL();
   */

  // @ts-expect-error TS(2339): Property 'formatAsBRL' does not exist on type 'Num... Remove this comment to see the full error message
  if (!Number.prototype.formatAsBRL)
    Object.defineProperty(Number.prototype, "formatAsBRL", {
      value: function value(signed: any, n: any, x: any, s: any, c: any) {
        if (signed === void 0) {
          signed = true;
        }

        return "" + (signed ? "R$ " : "") + this.format(n, x, s, c);
      },
    });
};

export default NumberExtension;
