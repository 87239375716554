import React, { useEffect, useState } from "react";
import "./styles.scss";
import MultiSelect from "react-select";
import { useLazyQuery } from "@apollo/client";
import ReCAPTCHA from "react-google-recaptcha";
import { CircularProgress } from "@mui/material";
import makeAnimated from "react-select/animated";
import BiudButton from "@newcomponentes/Buttons/BiudButton";
import HeaderStepsModal from "@newcomponentes/Modal/HeaderStepsModal";
import ListItems from "@components/RegisterModal/components/ListItems";
import { BUSINESS_OPERATING_AREAS_QUERY } from "@query/gql/operatingAreas";

interface IRegisterFormModalContainerProps {
  title?: string;
  emoji?: React.ReactNode;
  emojiAlt?: string;
  emojiSize?: number | string;
  subTitle?: string;
  stepsQnt?: number;
  currentStep?: number;
  primaryBtnTitle?: string;
  primaryBtnAction?: (param?: any, param2?: any) => void;
  primaryBtnLoading?: boolean;
  primaryBtnProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  secondaryBtnTitle?: string;
  secondaryBtnAction?: () => void;
  secondaryBtnLoading?: boolean;
  secondaryBtnProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  tertiaryBtnTitle?: string;
  tertiaryBtnAction?: () => void;
  tertiaryBtnLoading?: boolean;
  tertiaryBtnProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  list?: { label: string; value: number }[];
  listOtherOption?: boolean;
  operatingAreasSelected?: any[];
  setOperatingAreasSelected?: (param?: any) => void;
  listName?: string;
  itemSelected?: string;
  setItemSelected?: (param?: any) => void;
  children?: React.ReactNode;
  className?: string;
  animation?: string;
  enableRecaptcha?: boolean;
  setCaptcha?: (param?: any) => void;
}

function RegisterFormModalContainer({
  title = "",
  emoji = "",
  emojiAlt = "",
  emojiSize = 24,
  subTitle = "",
  currentStep = 1,
  stepsQnt = 1,
  primaryBtnTitle = "",
  primaryBtnAction = () => "",
  primaryBtnLoading = false,
  primaryBtnProps = {},
  secondaryBtnTitle = "",
  secondaryBtnAction = () => "",
  secondaryBtnLoading = false,
  secondaryBtnProps = {},
  tertiaryBtnTitle = "",
  tertiaryBtnAction = () => "",
  tertiaryBtnLoading = false,
  tertiaryBtnProps = {},
  list = [],
  listOtherOption = false,
  listName = "",
  itemSelected = "",
  setItemSelected = () => "",
  children = <></>,
  className = "",
  animation = "",
  enableRecaptcha = false,
  setCaptcha = () => "",
  operatingAreasSelected = [],
  setOperatingAreasSelected = () => "",
}: IRegisterFormModalContainerProps) {
  const animatedComponents = makeAnimated();

  const [operatingAreasAvailable, setOperatingAreasAvailable] = useState([]);
  const [searchedSegment, setSearchedSegment] = useState("");

  const [getOperatingAreas, { loading }] = useLazyQuery(BUSINESS_OPERATING_AREAS_QUERY);

  const getAllOperatingAreas = async () => {
    try {
      const resReq = await getOperatingAreas({ fetchPolicy: "cache-first" });
      const res = resReq.data;
      const selectedOperations = operatingAreasSelected.map((item: any) => item.id);
      const filteredOperatingAreas = res.BusinessOperatingAreas.filter(
        (item: any) => !selectedOperations.includes(item.id)
      );
      setOperatingAreasAvailable(filteredOperatingAreas);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (itemSelected === "others" && operatingAreasAvailable?.length === 0) getAllOperatingAreas();
  }, [itemSelected, operatingAreasAvailable]);

  const handleMultiSelectChanged = (value: any, action: any) => {
    if (action.action === "input-change") setSearchedSegment(value);
  };

  return (
    <section className={`register-your-business-modal-container ${className} ${animation}`}>
      <HeaderStepsModal
        stepsQnt={stepsQnt}
        currentStep={currentStep}
        title={title}
        emoji={emoji}
        emojiAlt={emojiAlt}
        emojiSize={emojiSize}
        subTitle={subTitle}
      />
      <main className={`register-your-business-main-container`}>{children}</main>
      {list && list.length > 0 && (
        <section className={`radio-itens-list-container`}>
          <ListItems
            list={list}
            listName={listName}
            itemSelected={itemSelected}
            setItemSelected={setItemSelected}
            listOtherOption={listOtherOption}
          />
        </section>
      )}
      {itemSelected === "others" && (
        <div className="smoothFadeIn" style={{ width: "100%" }}>
          <MultiSelect
            isMulti
            className="mult-select-produc-input basic-multi-select"
            classNamePrefix="select"
            components={animatedComponents}
            loadingMessage={() => "Carregando..."}
            onInputChange={(value, action) => handleMultiSelectChanged(value, action)}
            noOptionsMessage={() =>
              searchedSegment.length ? "Nenhuma categoria encontrada" : "Comece a digitar para buscar as categorias"
            }
            isLoading={loading}
            getOptionLabel={(option) => option.name}
            options={operatingAreasAvailable
              .filter((seg: any) => seg.name.toLowerCase().includes(searchedSegment.toLowerCase()))
              .map((seg: any) => ({
                ...seg,
                name: seg.name,
                value: seg.id,
              }))}
            closeMenuOnSelect={false}
            value={
              operatingAreasSelected?.map((category: any) => ({
                id: category.id,
                name: category.name ?? category.label,
                value: category.id,
              })) ?? []
            }
            onChange={(newValue) => setOperatingAreasSelected(newValue)}
            placeholder="Comece a digitar"
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                width: "100%",
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "transparent",
                minHeight: 56,
                cursor: "pointer",
                border: "1px solid #666666",
                "&:hover": {},
                borderRadius: 4,
              }),
              dropdownIndicator: (baseStyles) => ({
                ...baseStyles,
                color: "#666",
                marginRight: 3,
              }),
              indicatorSeparator: () => ({
                display: "none",
              }),
              multiValue: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "#0095FF",
                color: "#fff",
                borderRadius: 4,
              }),
              multiValueLabel: (baseStyles) => ({
                ...baseStyles,
                color: "#fff",
              }),
            }}
          />
        </div>
      )}
      {enableRecaptcha && (
        <ReCAPTCHA
          sitekey={import.meta.env.VITE_RECAPTCHA_KEY}
          onChange={(token: string | null) => setCaptcha(token)}
        />
      )}
      {(primaryBtnTitle || secondaryBtnTitle || tertiaryBtnTitle) && (
        <nav className={`register-business-navigation`}>
          {primaryBtnTitle && (
            <BiudButton
              buttonType="primary"
              id={`btn-${primaryBtnTitle}`}
              size="lg"
              bold={true}
              onClick={primaryBtnAction}
              style={{ width: "100%", padding: "1px 16px" }}
              {...primaryBtnProps}
            >
              {!primaryBtnLoading ? primaryBtnTitle : <CircularProgress size={20} style={{ color: "#ffffff" }} />}
            </BiudButton>
          )}
          {secondaryBtnTitle && (
            <BiudButton
              buttonType="text-secondary"
              id={`btn-${secondaryBtnTitle}`}
              size="lg"
              bold={false}
              onClick={secondaryBtnAction}
              style={{ width: "100%", padding: "1px 16px" }}
              {...secondaryBtnProps}
            >
              {!secondaryBtnLoading ? secondaryBtnTitle : <CircularProgress size={20} color="inherit" />}
            </BiudButton>
          )}
          {tertiaryBtnTitle && (
            <BiudButton
              buttonType="text-secondary"
              id={`btn-${tertiaryBtnTitle}`}
              size="lg"
              bold={false}
              onClick={tertiaryBtnAction}
              style={{ width: "100%", padding: "1px 16px" }}
              {...tertiaryBtnProps}
            >
              {!tertiaryBtnLoading ? tertiaryBtnTitle : <CircularProgress size={20} color="inherit" />}
            </BiudButton>
          )}
        </nav>
      )}
    </section>
  );
}

export default RegisterFormModalContainer;
