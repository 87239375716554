import ApolloClient from "apollo-boost";
export { gql } from "apollo-boost";
import { getGroup, isAdmin, logout } from "@services/auth";

const ONE_MINUTE = 1000 * 60;
export const CACHE_TIME = ONE_MINUTE * 10;

export const client = new ApolloClient({
  uri: import.meta.env.VITE_API_URL,
  request: (operation) => {
    const token = localStorage.getItem("app-biud-token");
    const reqBody = operation?.query?.loc?.source?.body;
    const isMutation = reqBody?.includes("mutation") && !reqBody?.includes("LogBusinessInteractivity");
    if (isMutation && getGroup()?.readonly && !isAdmin()) throw new Error("modelbusiness");

    operation.setContext({
      headers: {
        authorization: `Bearer ${token || "."}`,
      },
    });
  },
  onError: ({ networkError }: any) => {
    if (networkError?.statusCode === 401) logout();
  },
});
