import { client, gql } from "../config";
import { FetchPolicy } from "apollo-boost";
import { companySelected } from "@services/auth";
import {
  BIUD_CONNECT_INSTALLED_QUERY,
  BUSINESS_CERTIFICATES_QUERY,
  BUSINESS_CNPJ_QUERY,
  BUSINESS_FACEBOOK_INFO_QUERY,
  BUSINESS_FROM_GROUP,
  BUSINESS_LEADS_QUERY,
  BUSINESS_LOGS_QUERY,
  BUSINESS_NO_PRODUCTS_QUERY,
  BUSINESS_OPT_IN_PAGE_QUERY,
  BUSINESS_OPT_OUT_PAGE_QUERY,
  BUSINESS_RFV_QUERY,
  BUSINESS_USERS_QUERY,
  HASCERTIFIED_AND_PRODUCTNOTREVIEWED_QUERY,
} from "@query/gql/business";

export async function getRFV(variables: any, fetchPolicy: FetchPolicy = "no-cache") {
  const res = await client.query({
    query: BUSINESS_RFV_QUERY,
    variables: { id: variables.businessId },
    fetchPolicy,
  });

  return res.data.Business.data?.[0]?.RFV;
}

export async function getBusinessNoProducts(data: any, fetchPolicy: FetchPolicy = "no-cache") {
  const business = await client.query({
    query: BUSINESS_NO_PRODUCTS_QUERY,
    variables: data[0],
    fetchPolicy,
  });

  const Business = await business.data.Business.data;
  return { Business };
}

export async function getBusinessWithFacebookData(variables: { id: number }) {
  const business = await client.query({
    query: BUSINESS_FACEBOOK_INFO_QUERY,
    variables,
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return { Business };
}

export async function getBusinessFacebookData(data: any) {
  const businessQuery = gql`
    query Business($id: Int, $owner: Int, $dataSharingEnabled: Boolean, $active: Boolean) {
      Business(id: $id, owner: $owner, dataSharingEnabled: $dataSharingEnabled, active: $active) {
        data {
          facebookPage {
            facebookPageId
            pageToken
            businessManagerId
            adAccountId
            businessManagers {
              id
              name
            }
          }
        }
      }
    }
  `;

  const business = await client.query({
    query: businessQuery,
    variables: data,
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return { Business };
}

export async function getDataSharingEnabled() {
  const businessQuery = gql`
    query Business {
      Business(dataSharingEnabled: true, active: true) {
        data {
          id
          name
          cnpj
          logo
          isDataSharingEnabled
        }
      }
    }
  `;

  const business = await client.query({
    query: businessQuery,
  });

  const Business = await business.data.Business.data;
  return { Business };
}

export async function isBiudConnectInstalled(data: any) {
  const business = await client.query({
    query: BIUD_CONNECT_INSTALLED_QUERY,
    variables: data,
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return { Business };
}

export async function getBusinessPage(variables: any, fetchPolicy: FetchPolicy = "no-cache") {
  const businessPage = await client.query({
    query: BUSINESS_OPT_IN_PAGE_QUERY,
    variables,
    fetchPolicy,
  });

  const Business = await businessPage.data.Business.data;
  return { Business };
}

export async function getBusinessGoodbyPage(variables: any, fetchPolicy: FetchPolicy = "no-cache") {
  const businessPage = await client.query({
    query: BUSINESS_OPT_OUT_PAGE_QUERY,
    variables,
    fetchPolicy,
  });

  const Business = await businessPage.data.Business.data;
  return { Business };
}

export async function getBusinessByCnpj(cnpj: any) {
  const business = await client.query({
    query: BUSINESS_CNPJ_QUERY,
    variables: { cnpj: cnpj },
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return { Business };
}

export async function getIsDataEnrichmentEnabled(id: any) {
  const isDataEnrichmentEnabled = gql`
    query Business($id: Int) {
      Business(id: $id) {
        data {
          isDataEnrichmentEnabled
        }
      }
    }
  `;

  const business = await client.query({
    query: isDataEnrichmentEnabled,
    variables: { id: id },
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return { data: { Business } };
}

export async function getBusinessById(id: any) {
  const businessById = gql`
    query Business($id: Int) {
      Business(id: $id) {
        data {
          name
          logo
        }
      }
    }
  `;

  const business = await client.query({
    query: businessById,
    variables: { id: id },
    fetchPolicy: "no-cache",
  });

  const Business = await business.data.Business.data;
  return { Business };
}

export async function getBusinessHasCertified(data: any, fetchPolicy: FetchPolicy = "no-cache") {
  const business = await client.query({
    query: BUSINESS_CERTIFICATES_QUERY,
    variables: { id: data.businessId },
    fetchPolicy,
  });

  const Business = await business.data.Business.data;
  return { data: { Business } };
}

export async function getBusinessUsers(id: any, fetchPolicy: FetchPolicy = "no-cache") {
  const business = await client.query({
    query: BUSINESS_USERS_QUERY,
    variables: { id: id },
    fetchPolicy,
  });
  const Business = business.data.Business.data;
  return { data: { Business } };
}

export async function getBusinessUsersWithCurrentUser(variables: any, fetchPolicy: FetchPolicy = "no-cache") {
  const query = gql`
    query Business_businessUsersWithCurrentUser($id: Int) {
      Business(id: $id) {
        data {
          users {
            type
            user {
              id
              name
              email
              phone
              cpf
              enabled
            }
          }
        }
      }
      CurrentUser {
        id
        name
        email
        phone
      }
    }
  `;

  const res = await client.query({
    query,
    variables: { id: variables.businessId },
    fetchPolicy,
  });

  return {
    users: res.data.Business.data?.[0]?.users || [],
    currentUser: res.data.CurrentUser,
  };
}

export async function getBusinessLogs(fetchPolicy: FetchPolicy = "cache-first") {
  const res = await client.query({
    query: BUSINESS_LOGS_QUERY,
    variables: { id: companySelected() },
    fetchPolicy,
  });

  return res.data.Business.data?.[0]?.logs;
}

export async function leadsDataFranchise(data: any) {
  const business = await client.query({
    query: BUSINESS_LEADS_QUERY,
    variables: data,
  });

  const Business = await business.data.Business.data;
  return { Business };
}

export async function getFacebookToken(variables: any, noCache = true) {
  const query = gql`
    query FacebookToken($id: Int, $owner: Int, $dataSharingEnabled: Boolean, $active: Boolean) {
      Business(id: $id, owner: $owner, dataSharingEnabled: $dataSharingEnabled, active: $active) {
        data {
          id
          name
          cnpj
          createdAt
          facebookPage {
            pageToken
          }
        }
      }
    }
  `;

  const business = await client.query({
    query,
    variables,
    ...(noCache && { fetchPolicy: "no-cache" }),
  });

  const Business = await business.data.Business.data;
  return { Business };
}

export async function multGetHeaderStepsInfo(variables: any, fetchPolicy: FetchPolicy = "no-cache") {
  const query = gql`
    query HeaderStepsInfo(
      $businessIds: [Int]
      $businessId: Int
      $owner: Int
      $dataSharingEnabled: Boolean
      $active: Boolean
    ) {
      Business(id: $businessId, owner: $owner, dataSharingEnabled: $dataSharingEnabled, active: $active) {
        data {
          facebookPage {
            pageToken
          }
        }
      }
      WhatsappNumbers(businessId: $businessId) {
        id
        integrationCode
        displayPhoneNumber
      }
      dashboardActivations(businessIds: $businessIds) {
        all {
          count
        }
      }
    }
  `;

  const res = await client.query({
    query,
    variables,
    fetchPolicy,
  });

  return {
    facebook: { pageToken: res.data.Business?.data[0]?.facebookPage?.pageToken },
    whatsapp: res.data?.WhatsappNumbers?.[0],
    activations: res.data?.dashboardActivations.all.count,
  };
}

export async function getBusinessHasCertifiedAndProductNotReviwed(data: any, fetchPolicy: FetchPolicy = "no-cache") {
  const res = await client.query({
    query: HASCERTIFIED_AND_PRODUCTNOTREVIEWED_QUERY,
    variables: { id: data.businessId },
    fetchPolicy,
  });

  return {
    business: res.data.Business?.data,
    products: res.data.Products?.data || [],
  };
}

export async function getBusinessIdsFromGroup(groupId: number) {
  const res = await client.query({
    query: BUSINESS_FROM_GROUP,
    variables: { groupId },
  });

  return res.data.Business.data;
}
