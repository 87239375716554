const ArrayExtension = function () {
  /**
   * Plugin to check if a Arrays contains given value.
   * @param c: Character to be searched into String, ex: -.,, _-, , -, _-, AA, B, etc.
   *
   * Usage: Ex1: "aew".safeContains('a');
   */

  // @ts-expect-error TS(2339): Property 'safeContains' does not exist on type 'an... Remove this comment to see the full error message
  if (!Array.prototype.safeContains)
    Object.defineProperty(Array.prototype, "safeContains", {
      value: function value(c: any) {
        return c !== undefined ? this.indexOf(c) !== -1 : false;
      },
    });
  /**
   * Randomize the current array data
   *
   * Usage: Ex1: ["aew", "123", "aabb"].shuffle(); //["123", "aabb", "aew"]
   */

  // @ts-expect-error TS(2339): Property 'shuffle' does not exist on type 'any[]'.
  if (!Array.prototype.shuffle)
    // @ts-ignore
    Array.prototype.shuffle = function () {
      let i = this.length,
        j,
        temp;
      if (i === 0) return this;

      while (--i) {
        j = Math.floor(Math.random() * (i + 1));
        temp = this[i];
        this[i] = this[j];
        this[j] = temp;
      }

      return this;
    };
};

export default ArrayExtension;
