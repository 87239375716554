/** copy from unstorage
 * https://github.com/unjs/unstorage
 */

const r = (key: any) => "biud-plataforma::" + key;
let _storageListener: any;

const storage = {
  hasItem(key: any) {
    return Object.prototype.hasOwnProperty.call(window.localStorage, r(key));
  },

  getItem(key: any) {
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    return JSON.parse(window.localStorage.getItem(r(key)));
  },

  setItem(key: any, value: any) {
    return window.localStorage.setItem(r(key), JSON.stringify(value));
  },

  removeItem(key: any) {
    return window.localStorage.removeItem(r(key));
  },

  getKeys() {
    return Object.keys(window.localStorage);
  },

  clear() {
    // @ts-expect-error TS(2339): Property 'base' does not exist on type 'Window & t... Remove this comment to see the full error message
    if (!window.base) {
      window.localStorage.clear();
    } else {
      for (const key of Object.keys(window.localStorage)) {
        // eslint-disable-next-line no-unused-expressions
        window.localStorage?.removeItem(key);
      }
    }

    if (window.window && _storageListener) {
      window.window.removeEventListener("storage", _storageListener);
    }
  },

  watch(callback: any) {
    if (window.window) {
      _storageListener = (ev: any) => {
        if (ev.key) {
          callback(ev.newValue ? "update" : "remove", ev.key);
        }
      };

      window.window.addEventListener("storage", _storageListener);
    }
  },
};

export default storage;
