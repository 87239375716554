import { USER } from "./CurrentUserReducer";

export const setCurrentUser = (payload: any) => ({
  type: USER.LOGIN,
  payload,
});

export const logout = () => ({ type: USER.LOGOUT });

export const checkPayment = (payload: any) => ({
  type: USER.CHECK_PAYMENT,
  payload,
});

const exported = {
  setCurrentUser,
  logout,
  checkPayment,
};

export default exported;
