import { SvgIcon } from "@mui/material";

function Star(props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M22 9.67015C21.9368 9.48723 21.822 9.32657 21.6693 9.20765C21.5167 9.08872 21.3328 9.01664 21.14 9.00014L15.45 8.17015L12.9 3.00015C12.8181 2.83107 12.6903 2.68849 12.5311 2.58872C12.3719 2.48895 12.1879 2.43604 12 2.43604C11.8121 2.43604 11.6281 2.48895 11.4689 2.58872C11.3097 2.68849 11.1819 2.83107 11.1 3.00015L8.55 8.16014L2.86 9.00014C2.67492 9.02645 2.50092 9.10411 2.35775 9.2243C2.21458 9.3445 2.10796 9.50242 2.05 9.68015C1.99695 9.85382 1.99218 10.0387 2.03623 10.2148C2.08027 10.391 2.17146 10.5519 2.3 10.6801L6.43 14.6801L5.43 20.3601C5.3943 20.5476 5.41299 20.7414 5.48387 20.9186C5.55475 21.0958 5.67485 21.249 5.83 21.3601C5.98122 21.4683 6.15957 21.5321 6.34505 21.5444C6.53052 21.5568 6.71577 21.5172 6.88 21.4301L12 18.7601L17.1 21.4401C17.2403 21.5193 17.3989 21.5607 17.56 21.5601C17.7718 21.5609 17.9784 21.4944 18.15 21.3701C18.3051 21.259 18.4252 21.1058 18.4961 20.9286C18.567 20.7514 18.5857 20.5576 18.55 20.3701L17.55 14.6901L21.68 10.6901C21.8244 10.5678 21.9311 10.407 21.9877 10.2265C22.0444 10.0459 22.0486 9.85302 22 9.67015ZM15.85 13.6701C15.7327 13.7836 15.645 13.924 15.5944 14.0791C15.5439 14.2342 15.5321 14.3994 15.56 14.5601L16.28 18.7501L12.52 16.7501C12.3753 16.6731 12.2139 16.6328 12.05 16.6328C11.8861 16.6328 11.7247 16.6731 11.58 16.7501L7.82 18.7501L8.54 14.5601C8.56794 14.3994 8.55611 14.2342 8.50557 14.0791C8.45502 13.924 8.36728 13.7836 8.25 13.6701L5.25 10.6701L9.46 10.0601C9.622 10.0376 9.776 9.97569 9.9085 9.8798C10.041 9.78391 10.148 9.65698 10.22 9.51015L12 5.70015L13.88 9.52015C13.952 9.66698 14.059 9.79391 14.1915 9.8898C14.324 9.98569 14.478 10.0476 14.64 10.0701L18.85 10.6801L15.85 13.6701Z" />
    </SvgIcon>
  );
}

export default Star;
