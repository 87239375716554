import { useEffect, useState, useRef } from "react";

export default function useDelayState(initialState: any) {
  const [state, setState] = useState(initialState);
  const timeoutRef = useRef(null as number | null);

  const setStateAfter = (newState: any, delay: any, callback: any) => {
    if (delay === 0 || delay === undefined) {
      setState(newState);
    } else {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        if (callback) callback();
        setState(newState);
        timeoutRef.current = null;
      }, delay);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return [state, setStateAfter];
}
