import React from "react";
import "./styles.scss";
import { renderIcon } from "@services/utils";
import { StepsProgressBar } from "@newcomponentes";

interface IHeaderStepsModalProps {
  title: string;
  emoji?: React.ReactNode;
  emojiAlt?: string;
  emojiSize?: string | number;
  subTitle?: string;
  stepsQnt?: number;
  currentStep?: number;
  containerProps?: object;
}

function HeaderStepsModal({
  title = "",
  emoji,
  emojiAlt = "icon",
  emojiSize = 24,
  subTitle = "",
  currentStep = 0,
  stepsQnt = undefined,
  containerProps = {},
}: IHeaderStepsModalProps) {
  return (
    <header className="header-with-steps-modal-container" {...containerProps}>
      {!!stepsQnt && <StepsProgressBar currentStep={currentStep} stepsQnt={stepsQnt} />}
      {emoji && (
        <div id="header-with-steps-modal-emoji" className="hws-header-emoji-container">
          {renderIcon(emoji, emojiSize, emojiAlt)}
        </div>
      )}
      {title && <p className="header-steps-title">{title}</p>}
      {subTitle && <p className="header-steps-sub-title">{subTitle}</p>}
    </header>
  );
}

export default HeaderStepsModal;
