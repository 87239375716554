const defaultState = {
  data: null,
  filters: null,
};
export const PURCHASE = {
  INDEX: "purchase/index",
  FILTERS: "purchase/filters",
  CLEAR: "purchase/clear",
  LOGOUT: "purchase/logout",
};
export default function PurchaseReducer(state = defaultState, action: any) {
  const nextState = { ...state };

  switch (action.type) {
    case PURCHASE.INDEX:
      // @ts-expect-error TS(2339): Property 'index' does not exist on type '{ data: n... Remove this comment to see the full error message
      nextState.index = action.result;
      return nextState;
    case PURCHASE.FILTERS:
      nextState.filters = action.result;
      return nextState;
    default:
      return nextState;
  }
}
