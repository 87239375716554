import { getGroup } from "@/services/auth";

export async function handleMiaUserkey() {
  const group = getGroup();
  if (!group) throw new Error("Nenhuma rede selecionada");

  const url = `${import.meta.env.VITE_MIA_API_URL}/start-insights-procedure`;

  const body = JSON.stringify({ corporationId: group.id });

  await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  });
}
