import ArrayExtension from "./ArrayExtension";
import NumberExtension from "./NumberExtension";
import StringExtension from "./StringExtension";

export default {
  all: () => {
    NumberExtension();
    StringExtension();
    ArrayExtension();
  },
  NumberExtension,
  StringExtension,
  ArrayExtension,
};
