import React from "react";

export default function Version() {
  const version = import.meta.env.VITE_VERSION ?? [];
  const versionNumber =
    version.includes("release") || version.includes("staging") ? version.replace(/[^\d.]/g, "") : version;

  return (
    <span className="version-info" style={{ color: "#A3A3A3" }}>
      Versão {versionNumber}
    </span>
  );
}
