import { gql } from "@/graphql/apollo";

export const LOG_BUSINESS_INTERACTIVITY_MUTATION = gql`
  mutation LogBusinessInteractivity($section: String!, $businessId: Int!, $message: String!) {
    LogBusinessInteractivity(businessId: $businessId, section: $section, message: $message)
  }
`;

export const REMOVE_BUSINESS_USER_MUTATION = gql`
  mutation RemoveBusinessUser($userId: Int!, $businessId: Int!) {
    RemoveBusinessUser(userId: $userId, businessId: $businessId)
  }
`;

export const DISABLE_USER_MUTATION = gql`
  mutation DisableUser($id: Int!) {
    DisableUser(userId: $id)
  }
`;

export const REGISTER_BUSINESS_USER_MUTATION = gql`
  mutation RegisterBusinessUser($name: String!, $email: String!, $businessIds: [Int!]!, $type: BusinessUserType) {
    RegisterBusinessUser(name: $name, email: $email, businessIds: $businessIds, type: $type)
  }
`;

export const REGISTER_USER_MUTATION = gql`
  mutation RegisterUser($email: String!, $name: String!, $phone: String!, $password: String!) {
    RegisterUser(email: $email, name: $name, phone: $phone, password: $password) {
      id
      name
      phone
    }
  }
`;
