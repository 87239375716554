import { gql } from "@/graphql/apollo";

export const CITIES_QUERY = gql`
  query Cities($name: String, $ufs: [String], $ids: [Int]) {
    Cities(name: $name, ufs: $ufs, ids: $ids) {
      ibge
      name
      uf
    }
  }
`;

export const ADDRESS_CITIES_QUERY = gql`
  query AddressCities($name: String, $ufs: [String], $businessGroup: Int) {
    AddressCities(name: $name, ufs: $ufs, businessGroup: $businessGroup)
  }
`;
