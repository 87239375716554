import { SvgIcon } from "@mui/material";

function Thunder(props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M19.8646 8.60059C19.79 8.4293 19.6691 8.28231 19.5153 8.17627C19.3615 8.07022 19.1811 8.00936 18.9946 8.00059H14.4146L15.6846 3.26059C15.725 3.11226 15.7307 2.95661 15.7012 2.80572C15.6717 2.65483 15.6079 2.51278 15.5146 2.39059C15.4204 2.26823 15.2991 2.16934 15.1603 2.10168C15.0215 2.03402 14.869 1.99942 14.7146 2.00059H7.71455C7.48879 1.99285 7.26706 2.06179 7.08548 2.19616C6.90389 2.33053 6.77315 2.52242 6.71455 2.74059L4.03455 12.7406C3.99378 12.8916 3.98902 13.0502 4.02066 13.2034C4.05229 13.3566 4.11945 13.5003 4.21671 13.6228C4.31398 13.7454 4.43864 13.8434 4.58069 13.909C4.72273 13.9746 4.87818 14.006 5.03455 14.0006H8.88455L7.07455 20.7406C7.01525 20.9579 7.03107 21.1889 7.11946 21.3961C7.20784 21.6034 7.3636 21.7747 7.56151 21.8823C7.75942 21.9899 7.98787 22.0276 8.20986 21.9892C8.43184 21.9507 8.63434 21.8385 8.78455 21.6706L19.6846 9.67059C19.8143 9.52847 19.9004 9.35192 19.9323 9.16212C19.9642 8.97232 19.9407 8.77735 19.8646 8.60059ZM10.0746 17.2806L11.1546 13.2806C11.1997 13.1301 11.2087 12.9711 11.1809 12.8165C11.1531 12.6618 11.0893 12.5159 10.9946 12.3906C10.8993 12.2669 10.7765 12.1672 10.6358 12.0994C10.4952 12.0317 10.3406 11.9979 10.1846 12.0006H6.34455L8.48455 4.00059H13.4146L12.1446 8.74059C12.1038 8.89164 12.099 9.05016 12.1307 9.20339C12.1623 9.35662 12.2294 9.50029 12.3267 9.62284C12.424 9.7454 12.5486 9.84342 12.6907 9.90903C12.8327 9.97463 12.9882 10.006 13.1446 10.0006H16.7146L10.0746 17.2806Z" />
    </SvgIcon>
  );
}

export default Thunder;
