import React from "react";
import { SvgIcon } from "@mui/material";

export default function VolumeDown(props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M18.83 9.16997C18.7368 9.07673 18.6261 9.00277 18.5042 8.95231C18.3824 8.90185 18.2519 8.87588 18.12 8.87588C17.9881 8.87588 17.8576 8.90185 17.7358 8.95231C17.6139 9.00277 17.5032 9.07673 17.41 9.16997C17.3168 9.26321 17.2428 9.3739 17.1923 9.49572C17.1419 9.61754 17.1159 9.74811 17.1159 9.87997C17.1159 10.0118 17.1419 10.1424 17.1923 10.2642C17.2428 10.386 17.3168 10.4967 17.41 10.59C17.7856 10.9633 17.9978 11.4704 18 12C18.0002 12.2913 17.9368 12.5792 17.8142 12.8435C17.6915 13.1078 17.5126 13.3421 17.29 13.53C17.1887 13.6139 17.105 13.717 17.0437 13.8334C16.9823 13.9497 16.9445 14.077 16.9325 14.208C16.9204 14.339 16.9343 14.471 16.9733 14.5967C17.0124 14.7223 17.0758 14.8389 17.16 14.94C17.2447 15.0405 17.3483 15.1234 17.465 15.1838C17.5817 15.2443 17.7092 15.2811 17.8401 15.2922C17.9711 15.3034 18.1029 15.2886 18.2282 15.2488C18.3534 15.2089 18.4696 15.1448 18.57 15.06C19.0171 14.6851 19.3768 14.2169 19.6238 13.6883C19.8709 13.1597 19.9992 12.5835 20 12C19.9944 10.9401 19.5744 9.92442 18.83 9.16997ZM14.43 4.09997C14.2676 4.02971 14.0895 4.0038 13.9138 4.02488C13.7381 4.04597 13.5712 4.11329 13.43 4.21997L8.65 7.99997H5C4.73478 7.99997 4.48043 8.10533 4.29289 8.29286C4.10536 8.4804 4 8.73475 4 8.99997V15C4 15.2652 4.10536 15.5195 4.29289 15.7071C4.48043 15.8946 4.73478 16 5 16H8.65L13.38 19.78C13.5559 19.9211 13.7744 19.9987 14 20C14.1494 20.0024 14.297 19.9681 14.43 19.9C14.6002 19.8189 14.744 19.6914 14.8448 19.5322C14.9457 19.373 14.9995 19.1885 15 19V4.99997C14.9995 4.81148 14.9457 4.62699 14.8448 4.46775C14.744 4.30851 14.6002 4.18102 14.43 4.09997ZM13 16.92L9.62 14.22C9.44406 14.0788 9.22556 14.0013 9 14H6V9.99997H9C9.22556 9.99866 9.44406 9.92113 9.62 9.77997L13 7.07997V16.92Z" />
    </SvgIcon>
  );
}
