import storage from "../storage";
export interface ICompanyStore {
  companies: any;
  company: any;
  businessPage: any;
  goodbyePage: any;
  loading: boolean;
  readOnly: boolean;
  isChooseGroupBusinessRequired: boolean;
}

const defaultState = {
  companies: storage.getItem("companies"),
  company: null,
  businessPage: null,
  goodbyePage: null,
  loading: false,
  readOnly: false,
  isChooseGroupBusinessRequired: false,
};
export const COMPANY = {
  INDEX: "company/index",
  LIST: "company/list",
  BUSINESS_PAGE: "company/business-page",
  GOODBYE_PAGE: "company/goodbye-page",
  LOADING: "company/loading",
  READ_ONLY: "company/read-only",
  CHOOSE_GROUP_BUSINESS_REQUIRED: "company/choose-group-business-required",
  LOGOUT: "company/logout",
  CLEAR: "company/clear",
};
export default function CompanyReducer(state = defaultState, action: any) {
  let nextState = { ...state };

  switch (action.type) {
    case COMPANY.INDEX:
      nextState.company = action.result;
      return nextState;

    case COMPANY.LIST:
      storage.setItem("companies", action.result);
      nextState.companies = action.result;
      return nextState;

    case COMPANY.BUSINESS_PAGE:
      nextState.businessPage = action.result;
      return nextState;

    case COMPANY.GOODBYE_PAGE:
      nextState.goodbyePage = action.result;
      return nextState;

    case COMPANY.LOADING:
      nextState.loading = action.result;
      return nextState;

    case COMPANY.READ_ONLY:
      nextState.readOnly = action.result;
      return nextState;

    case COMPANY.CHOOSE_GROUP_BUSINESS_REQUIRED:
      nextState.isChooseGroupBusinessRequired = action.result;
      return nextState;

    case COMPANY.CLEAR:
      nextState = { ...defaultState };
      nextState.companies = { ...state.companies };
      return nextState;

    case COMPANY.LOGOUT:
      storage.removeItem("companies");
      nextState = { ...defaultState };
      return nextState;
    default:
      return nextState;
  }
}
