const StringExtension = function () {
  // @ts-expect-error TS(2339): Property 'brazilianFormatToDate' does not exist on... Remove this comment to see the full error message
  if (!String.prototype.brazilianFormatToDate)
    Object.defineProperty(String.prototype, "brazilianFormatToDate", {
      value: function value() {
        const dateParts = this.split("/");
        return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      },
    });
  /**
   * {JSDoc}
   *
   * The splice() method changes the content of a string by removing a range of
   * characters and/or adding new characters.
   *
   * @this {String}
   * @param {number} start Index at which to start changing the string.
   * @param {number} delCount An integer indicating the number of old chars to remove.
   * @param {string} newSubStr The String that is spliced in.
   * @return {string} A new string with the spliced substring.
   */

  // @ts-expect-error TS(2551): Property 'splice' does not exist on type 'String'.... Remove this comment to see the full error message
  if (!String.prototype.splice)
    Object.defineProperty(String.prototype, "splice", {
      value: function value(start: any, delCount: any, newSubStr: any) {
        return this.slice(0, start) + newSubStr + this.slice(start + Math.abs(delCount));
      },
    });
  /**
   * Plugin that's generate a hashcode of a string
   *
   * Usage: Ex1: "ABC123D-F*G".simpleHashCode(); //output: 685091434
   */

  // @ts-expect-error TS(2339): Property 'simpleHashCode' does not exist on type '... Remove this comment to see the full error message
  if (!String.prototype.simpleHashCode)
    Object.defineProperty(String.prototype, "simpleHashCode", {
      value: function value() {
        let hash = 0,
          i,
          chr;
        if (this.length === 0) return hash;

        for (i = 0; i < this.length; i++) {
          chr = this.charCodeAt(i);
          hash = (hash << 5) - hash + chr;
          hash |= 0; // Convert to 32bit integer
        }

        return hash;
      },
    });
  /**
   * Plugin to extract numbers of Strings, returns a String containing only numbers and other escaped characters.
   * @param s: Chars to scape, ex: -.,, _-, , -, _-
   *
   * Usage: Ex1: "ABC123D-F*G".onlyNumbers();
   * Usage: Ex2: "ABC123D-F*G".onlyNumbers("D");
   * Usage: Ex3: "ABC123D-F*G".onlyNumbers("FG");
   * Usage: Ex4: "ABC123D-F*G".onlyNumbers("FG*-");
   * Usage: Ex5: "ABC123D-F*G".onlyNumbers("*-");
   */

  // @ts-expect-error TS(2339): Property 'onlyNumbers' does not exist on type 'Str... Remove this comment to see the full error message
  if (!String.prototype.onlyNumbers)
    Object.defineProperty(String.prototype, "onlyNumbers", {
      value: function value(s: any) {
        let patternBase = "[^0-9{*}]";
        if (s) patternBase = patternBase.replace("{*}", s);
        else patternBase = patternBase.replace("{*}", "");
        return this.replace(new RegExp(patternBase, "g"), "");
      },
    });
  /**
   * Plugin to extract Alpha chars of Strings, returns a String containing only Alpha and other escaped characters.
   * @param s: Chars to scape, ex: -.,, _-, , -, _-
   *
   * Usage: Ex1: "ABC123D-F*G".onlyAlpha();
   * Usage: Ex2: "ABC123D-F*G".onlyAlpha("1");
   * Usage: Ex3: "ABC123D-F*G".onlyAlpha("23");
   * Usage: Ex4: "ABC123D-F*G".onlyAlpha("-");
   * Usage: Ex5: "ABC123D-F*G".onlyAlpha("*-");
   */

  // @ts-expect-error TS(2339): Property 'onlyAlpha' does not exist on type 'Strin... Remove this comment to see the full error message
  if (!String.prototype.onlyAlpha)
    Object.defineProperty(String.prototype, "onlyAlpha", {
      value: function value(s: any) {
        let patternBase = "[^A-Za-z{*}]";
        if (s) patternBase = patternBase.replace("{*}", s);
        else patternBase = patternBase.replace("{*}", "");
        return this.replace(new RegExp(patternBase, "g"), "");
      },
    });
  /**
   * Plugin to extract Alphanumeric chars of Strings, returns a String containing only Alphanumeric and other escaped characters.
   * @param s: Chars to scape, ex: -.,, _-, , -, _-
   *
   * Usage: Ex1: "ABC123D-F*G".onlyAlphanumeric(); //ABC123DFG
   * Usage: Ex2: "ABC123D-F*G".onlyAlphanumeric("*"); //ABC123DF*G
   */

  // @ts-expect-error TS(2339): Property 'onlyAlphanumeric' does not exist on type... Remove this comment to see the full error message
  if (!String.prototype.onlyAlphanumeric)
    Object.defineProperty(String.prototype, "onlyAlphanumeric", {
      value: function value(s: any) {
        if (s === void 0) {
          s = "";
        }

        return this.replace(new RegExp("[^A-Za-z0-9" + s + "]", "g"), "");
      },
    });
  /**
   * Same of Alphanumeric, but don't allow number as first char of a String
   * @param s: Chars to scape, ex: -.,, _-, , -, _-
   *
   * Usage: Ex1: "098ABC123D-F*G".onlyAlphanumeric(); //ABC123DFG
   * Usage: Ex2: "7-65ABC123D-F*G".onlyAlphanumeric("*-"); //-ABC123DF*G
   */

  // @ts-expect-error TS(2339): Property 'onlyAlphanumericUnderscoreAlphaFirst' do... Remove this comment to see the full error message
  if (!String.prototype.onlyAlphanumericUnderscoreAlphaFirst)
    Object.defineProperty(String.prototype, "onlyAlphanumericUnderscoreAlphaFirst", {
      value: function value() {
        return this.replace(new RegExp("^[^a-zA-Z_$]*|[^A-Za-z0-9_$]", "g"), "");
      },
    });
  /**
   * Cast first char of a String in uppercase
   *
   * Usage: Ex1: "oi mesquitao tao tao".capitalize(); //Oi mesquitao tao tao
   */

  // @ts-expect-error TS(2339): Property 'capitalize' does not exist on type 'Stri... Remove this comment to see the full error message
  if (!String.prototype.capitalize)
    Object.defineProperty(String.prototype, "capitalize", {
      value: function value() {
        return this.charAt(0).toUpperCase() + this.slice(1);
      },
    });
  /**
   * Plugin to convert a formatted Brazilian Real String to float.
   *
   * Usage: Ex1: "R$ 100,10".brazilianRealToFloat();
   */

  // @ts-expect-error TS(2339): Property 'brazilianRealToFloat' does not exist on ... Remove this comment to see the full error message
  if (!String.prototype.brazilianRealToFloat)
    Object.defineProperty(String.prototype, "brazilianRealToFloat", {
      value: function value() {
        //Se o parametro ja for number (ou seja, sem formato), nao converter mais nada, apenas devolver.
        if (isNaN(this)) {
          const val = parseFloat(this.onlyNumbers(",").replace(",", "."));
          return isNaN(val) ? 0 : val;
        } else {
          return parseFloat(this);
        }
      },
    });
  /**
   * Utility method to check if a String is a valid Cellphone.
   *  @param hasAreaCode: Define if the number will be validated using area code
   *
   * Usage: Ex1: "61999711616".isCellphone(); //true
   * Usage: Ex2: "(61)99971-1616".isCellphone(); //true
   *
   * Usage: Ex3: "999711616".isCellphone(false); //true
   * Usage: Ex4: "99971-1616".isCellphone(false); //true
   *
   * Usage: Ex5: "99971-1616".isCellphone(); //false, wrong size. Missing the Area Code 61.
   * Usage: Ex6: "999711616".isCellphone(); //false, wrong size. Missing the Area Code 61.
   */

  // @ts-expect-error TS(2339): Property 'isCellphone' does not exist on type 'Str... Remove this comment to see the full error message
  if (!String.prototype.isCellphone)
    Object.defineProperty(String.prototype, "isCellphone", {
      value: function value(hasAreaCode: any) {
        if (hasAreaCode === void 0) {
          hasAreaCode = true;
        }

        const position = hasAreaCode ? 2 : 0;
        const size = hasAreaCode ? 11 : 9;
        return this.onlyNumbers().length === size && parseInt(this.onlyNumbers().charAt(position)) >= 7;
      },
    });
  /**
   * Utility method to check if a String is a valid Phone.
   *  @param hasAreaCode: Define if the number will be validated using area code
   *
   * Usage: Ex1: "6233331886".isPhone(); //true
   * Usage: Ex1: "33331886".isPhone(false); //true
   */

  // @ts-expect-error TS(2339): Property 'isPhone' does not exist on type 'String'... Remove this comment to see the full error message
  if (!String.prototype.isPhone)
    Object.defineProperty(String.prototype, "isPhone", {
      value: function value(hasAreaCode: any) {
        if (hasAreaCode === void 0) {
          hasAreaCode = true;
        }

        const min = hasAreaCode ? 10 : 8;
        const max = hasAreaCode ? 11 : 9;
        return this.onlyNumbers().length >= min && this.onlyNumbers().length <= max;
      },
    });
  /**
   * Utility method to check if a String is a valid String Date
   *
   * Usage: Ex1: "16/04/1957".isStringDate(moment); //true
   * Usage: Ex2: "16041957".isStringDate(moment); //false
   */

  // @ts-expect-error TS(2339): Property 'isStringDate' does not exist on type 'St... Remove this comment to see the full error message
  if (!String.prototype.isStringDate)
    Object.defineProperty(String.prototype, "isStringDate", {
      value: function value(moment: any, format: any) {
        if (format === void 0) {
          format = "DD/MM/YYYY";
        }

        if (!moment || !moment.isMoment)
          throw Error("You must inform the moment.js instance as first parameter to do the check.");
        return this.length === 10 && moment(this, format).isValid();
      },
    });
  /**
   * Utility method to check if a String is a valid email.
   *
   * Usage: Ex1: "rodrigo@ae.com".isEmail();
   */

  // @ts-expect-error TS(2339): Property 'isEmail' does not exist on type 'String'... Remove this comment to see the full error message
  if (!String.prototype.isEmail)
    Object.defineProperty(String.prototype, "isEmail", {
      value: function value() {
        // eslint-disable-next-line no-useless-escape
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(this);
      },
    });
  /**
   * Utility method to check if a String is a valid URL.
   *
   * Usage: Ex1: "http://test.com.br".isURL();
   */

  // @ts-expect-error TS(2339): Property 'isURL' does not exist on type 'String'.
  if (!String.prototype.isURL)
    Object.defineProperty(String.prototype, "isURL", {
      value: function value() {
        const pattern =
          /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
        return pattern.test(this);
      },
    });
  /**
   * Utility method to check if a String is a valid CEP.
   *
   * Usage: Ex1: "70.680-600".isCEP();
   */

  // @ts-expect-error TS(2339): Property 'isCEP' does not exist on type 'String'.
  if (!String.prototype.isCEP)
    Object.defineProperty(String.prototype, "isCEP", {
      value: function value() {
        return this.onlyNumbers().length === 8;
      },
    });
  /**
   * Utility method to check if a String is a valid CPF.
   *
   * Usage: Ex1: "02687403130".isCPF();
   */

  // @ts-expect-error TS(2339): Property 'isCPF' does not exist on type 'String'.
  if (!String.prototype.isCPF)
    Object.defineProperty(String.prototype, "isCPF", {
      value: function value() {
        let numbers,
          digits,
          sum,
          i,
          result,
          equalDigits = 1;

        if (this.length < 11) {
          return false;
        }

        for (i = 0; i < this.length - 1; i++) {
          if (this.charAt(i) !== this.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = this.substring(0, 9);
          digits = this.substring(9);
          sum = 0;

          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
          if (result !== Number(digits.charAt(0))) return false;
          numbers = this.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
          return result === Number(digits.charAt(1));
        } else {
          return false;
        }
      },
    });
  /**
   * Utility method to check if a String is a valid CNPJ.
   */

  // @ts-expect-error TS(2339): Property 'isCNPJ' does not exist on type 'String'.
  if (!String.prototype.isCNPJ)
    Object.defineProperty(String.prototype, "isCNPJ", {
      value: function value() {
        let numbers,
          digits,
          sum,
          i,
          result,
          position,
          size,
          equalDigits = 1;
        if (this.length < 14 && this.length < 15) return false;

        for (i = 0; i < this.length - 1; i++) {
          if (this.charAt(i) !== this.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          size = this.length - 2;
          numbers = this.substring(0, size);
          digits = this.substring(size);
          sum = 0;
          position = size - 7;

          for (i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * position--;
            if (position < 2) position = 9;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
          if (result !== Number(digits.charAt(0))) return false;
          size += 1;
          numbers = this.substring(0, size);
          sum = 0;
          position = size - 7;

          for (i = size; i >= 1; i--) {
            sum += numbers.charAt(size - i) * position--;
            if (position < 2) position = 9;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
          return result === Number(digits.charAt(1));
        } else return false;
      },
    });
  /**
   * Plugin to count the number of characters present in a current string
   * @param c: Character to be counted, ex: -.,, _-, , -, _-
   *
   * Usage: Ex1: "ABCCD".count("C"); //2
   */

  // @ts-expect-error TS(2339): Property 'count' does not exist on type 'String'.
  if (!String.prototype.count)
    Object.defineProperty(String.prototype, "count", {
      value: function value(c: any) {
        if (c) {
          const size = this.match(new RegExp(c, "g"));
          return size ? size.length : 0;
        }

        return 0;
      },
    });
  /**
   * Plugin to check if a given String contains given value.
   * @param c: Character to be searched into String, ex: -.,, _-, , -, _-, AA, B, etc.
   *
   * Usage: Ex1: "aew".contains('a'); //true
   */

  // @ts-expect-error TS(2339): Property 'safeContains' does not exist on type 'St... Remove this comment to see the full error message
  if (!String.prototype.safeContains)
    Object.defineProperty(String.prototype, "safeContains", {
      value: function value(c: any) {
        return c !== undefined && (c + "").length > 0 ? this.indexOf(c + "") !== -1 : false;
      },
    });
  /**
   * Define a function to replace all chars to an string.
   *
   * @param from: String to be replaced.
   * @param to: String to replace.
   *
   * Usage: Ex1: "RODRIGO".replaceAll('O', 'E'); //REDRIGE
   */

  if (!String.prototype.replaceAll)
    Object.defineProperty(String.prototype, "replaceAll", {
      value: function value(from: any, to: any) {
        const escapeRegExp = function escapeRegExp(string: any) {
          // eslint-disable-next-line no-useless-escape
          return string.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        };

        return this.replace(new RegExp(escapeRegExp(from), "g"), to);
      },
    });
  /**
   * Define a function to replace tokens of a given JSON object.
   * For each JSON key try to find corresponding token on base string and replace with JSON[key] value
   *
   * @param json: JSON tokens to replace base string.
   * @param defaultDelimiterActive: If true, default REACT ROUTER delimiter will be used in conjuction with json key
   *
   * Usage: Ex1: "/path/:idPath".replaceTokens({idPath: "aew"}); ///path/aew
   *        Ex2: "/path/:idPath".replaceTokens({idPath: "aew"}, false); ///path/:aew
   *        Ex3: "aew rodrigo aew".replaceTokens({rodrigo: "aewww"}); ///aew rodrigo aew
   *        Ex4: "aew rodrigo aew".replaceTokens({rodrigo: "aewww"}, false); ///aew aewww aew
   */

  // @ts-expect-error TS(2339): Property 'replaceTokens' does not exist on type 'S... Remove this comment to see the full error message
  if (!String.prototype.replaceTokens)
    Object.defineProperty(String.prototype, "replaceTokens", {
      value: function value(json: any, defaultDelimiterActive: any) {
        if (defaultDelimiterActive === void 0) {
          defaultDelimiterActive = true;
        }

        if (!json || Object.keys(json).length === 0) return this;
        let str = this;

        for (const key in json) {
          // eslint-disable-next-line no-prototype-builtins
          if (json.hasOwnProperty(key)) str = str.replace((defaultDelimiterActive ? ":" : "") + key, json[key]);
        }

        return str;
      },
    });
  /**
   * Replace a char in specific index
   * @param index
   * @param character
   * @returns {string}
   */

  // @ts-expect-error TS(2551): Property 'replaceAt' does not exist on type 'Strin... Remove this comment to see the full error message
  if (!String.prototype.replaceAt)
    Object.defineProperty(String.prototype, "replaceAt", {
      value: function value(index: any, character: any) {
        return this.substr(0, index) + character + this.substr(index + character.length);
      },
    });
  /**
   * Reverse the String
   *
   * Usage: Ex1: "RODRIGO".reverse();
   */

  // @ts-expect-error TS(2339): Property 'reverse' does not exist on type 'String'... Remove this comment to see the full error message
  if (!String.prototype.reverse)
    Object.defineProperty(String.prototype, "reverse", {
      value: function value() {
        return this.split("").reverse().join("");
      },
    });
  /**
   * Unmask a String value leaving only Alphanumeric chars.
   *
   * Usage: Ex1: '026.874.031-30'.unmask(); //02687403130
   */

  // @ts-expect-error TS(2339): Property 'unmask' does not exist on type 'String'.
  if (!String.prototype.unmask)
    Object.defineProperty(String.prototype, "unmask", {
      value: function value() {
        const exp = /[^A-Za-z0-9]/g;
        return this.replace(exp, "");
      },
    });
  /***
   * Generic fixed size mask formatter.
   *
   * @param mask: The mask to be applied on current value
   * @param fillReverse: Boolean value. If true, applies the mask from right to left, if false or undefined,
   * applies from left to right.
   *
   * Usage: Ex1: '02687403130'.mask('000.000.000-00'); //026.874.031-30
   *        Ex2: '02687403130'.mask('000.000.000-00', true); //026.874.031-30
   *        Ex3: '0268'.mask('000.000.000-00'); //026.8
   *        Ex4: '0268740'.mask('000.000.000-00'); //026.874.0
   *        Ex5: '0268'.mask('000.000.000-00', true); //02-68
   *        Ex6: '026874031'.mask('000.000.000-00', true); //0.268.740-31
   *
   *
   *        Ex7: '2000'.mask('0.000.000.000,00', true); //20,00
   *        Ex8: '20001'.mask('0.000.000.000,00', true); //200,01
   *        Ex9: '200012'.mask('0.000.000.000,00', true); //2.000,12
   *
   * @see Another approach is Number.format for dynamic size numbers, money, etc.
   *
   */

  // @ts-expect-error TS(2339): Property 'mask' does not exist on type 'String'.
  if (!String.prototype.mask)
    Object.defineProperty(String.prototype, "mask", {
      value: function value(mask: any, fillReverse: any) {
        if (fillReverse === void 0) {
          fillReverse = false;
        }

        if (!mask || typeof mask !== "string") return this;
        let value = fillReverse === true ? this.unmask().reverse() : this.unmask();
        const maskArray = fillReverse === true ? mask.split("").reverse() : mask.split("");
        const delimiters = [
          "(",
          ")",
          "{",
          "}",
          "[",
          "]",
          '"',
          "'",
          "<",
          ">",
          "/",
          "*",
          "\\",
          "%",
          "?",
          ";",
          ":",
          "&",
          "$",
          "#",
          "@",
          "!",
          "-",
          "_",
          "+",
          "=",
          "~",
          "`",
          "^",
          ".",
          ",",
          " ",
        ];
        maskArray.forEach(function (e, idx) {
          // @ts-expect-error TS(2339): Property 'safeContains' does not exist on type 'st... Remove this comment to see the full error message
          if (delimiters.safeContains(e) && value.slice(idx) !== "")
            value = [value.slice(0, idx), e, value.slice(idx)].join("");
        });
        return fillReverse === true ? value.reverse() : value;
      },
    });
  /***
   * Mask Money shortcut
   * Accepts up to billion
   */

  // @ts-expect-error TS(2339): Property 'maskMoney' does not exist on type 'Strin... Remove this comment to see the full error message
  if (!String.prototype.maskMoney)
    Object.defineProperty(String.prototype, "maskMoney", {
      value: function value() {
        return this.unmask().onlyNumbers().mask("000.000.000.000,00", true);
      },
    });
  /***
   * Mask CPF shortcut
   */

  // @ts-expect-error TS(2339): Property 'maskCPF' does not exist on type 'String'... Remove this comment to see the full error message
  if (!String.prototype.maskCPF)
    Object.defineProperty(String.prototype, "maskCPF", {
      value: function value() {
        return this.unmask().mask("000.000.000-00");
      },
    });
  /***
   * Mask CNPJ shortcut
   */

  // @ts-expect-error TS(2339): Property 'maskCNPJ' does not exist on type 'String... Remove this comment to see the full error message
  if (!String.prototype.maskCNPJ)
    Object.defineProperty(String.prototype, "maskCNPJ", {
      value: function value() {
        return this.unmask().mask("00.000.000/0000-00");
      },
    });
  /***
   * Mask CPF/CNPJ shortcut based on string length
   */

  // @ts-expect-error TS(2339): Property 'maskCPForCNPJ' does not exist on type 'S... Remove this comment to see the full error message
  if (!String.prototype.maskCPForCNPJ)
    Object.defineProperty(String.prototype, "maskCPForCNPJ", {
      value: function value() {
        return this.unmask().length <= 11 ? this.maskCPF() : this.maskCNPJ();
      },
    });
  /***
   * Mask phone shortcut based on string length
   */

  // @ts-expect-error TS(2339): Property 'maskPhone' does not exist on type 'Strin... Remove this comment to see the full error message
  if (!String.prototype.maskPhone)
    Object.defineProperty(String.prototype, "maskPhone", {
      value: function value() {
        return parseInt(this.unmask().length) === 11 ? this.mask("(00) 0 0000-0000") : this.mask("(00) 0000-0000");
      },
    });
  /***
   * Mask date datas "10/10/2013"
   */

  // @ts-expect-error TS(2339): Property 'maskDate' does not exist on type 'String... Remove this comment to see the full error message
  if (!String.prototype.maskDate)
    Object.defineProperty(String.prototype, "maskDate", {
      value: function value() {
        return this.mask("00/00/0000");
      },
    });
  /***
   * Mask hour "11:00"
   */

  // @ts-expect-error TS(2339): Property 'maskHour' does not exist on type 'String... Remove this comment to see the full error message
  if (!String.prototype.maskHour)
    Object.defineProperty(String.prototype, "maskHour", {
      value: function value() {
        return this.mask("00:00");
      },
    });
  /***
   * Mask CEP Brasil shortcut
   */

  // @ts-expect-error TS(2339): Property 'maskZipCode' does not exist on type 'Str... Remove this comment to see the full error message
  if (!String.prototype.maskZipCode)
    Object.defineProperty(String.prototype, "maskZipCode", {
      value: function value() {
        return this.unmask().mask("00.000-000");
      },
    });
  /***
   * Return the first char from the current string
   *
   * @param uppercase: If true, return char as uppercase, otherwise, returns lowercase
   */

  // @ts-expect-error TS(2339): Property 'firstChar' does not exist on type 'Strin... Remove this comment to see the full error message
  if (!String.prototype.firstChar)
    Object.defineProperty(String.prototype, "firstChar", {
      value: function value(uppercase: any) {
        if (uppercase === void 0) {
          uppercase = false;
        }

        const value = this.substring(0, 1);
        return uppercase ? value.toUpperCase() : value.toLowerCase();
      },
    });
  /***
   * Truncate the string on desired char
   *
   * @param n: Size of returning string
   * @param useReticence: If true, concat ... at end of returning string
   */

  // @ts-expect-error TS(2339): Property 'truncate' does not exist on type 'String... Remove this comment to see the full error message
  if (!String.prototype.truncate)
    Object.defineProperty(String.prototype, "truncate", {
      value: function value(n: any, useReticence: any) {
        if (useReticence === void 0) {
          useReticence = true;
        }

        if (this.length <= n) return this.toString();
        let subString = this.substr(0, n - 1);
        subString = subString.substr(0, subString.lastIndexOf(" "));
        return useReticence ? subString + " ..." : subString;
      },
    });
};
export default StringExtension;
