import React from "react";
import "./styles.scss";
import biudLogo from "@imgs/biud_transparent.png";

export default function LoadingPage() {
  return (
    <div className="biud-loading-page">
      <img src={biudLogo} alt="Biud logo" className="biud-loading" />
    </div>
  );
}
