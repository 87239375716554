import { PURCHASE } from "./PurchaseReducer";

export const setFilters = (filters: any) => (dispatch: any) => {
  dispatch({
    type: PURCHASE.FILTERS,
    result: filters,
  });
};

export const setPurchases = (data: any) => (dispatch: any) => {
  dispatch({
    type: PURCHASE.INDEX,
    result: data,
  });
};

export const logout = () => (dispatch: any) => {
  dispatch({
    type: PURCHASE.LOGOUT,
  });
};
export const clear = () => (dispatch: any) => {
  dispatch({
    type: PURCHASE.CLEAR,
  });
};

const exported = {
  setFilters,
  setPurchases,
  clear,
  logout,
};

export default exported;
