import React, { memo, useEffect } from "react";
import "./styles.scss";
import { bool, func, number, string } from "prop-types";
import { CheckCircle } from "@newcomponentes/CustomIcons";

function PasswordValidatorSteps({
  password,
  setIsPasswordValid,
  passwordMinLength,
  validatePasswordLength,
  validateUpperCase,
  validateLowerCase,
  validateHasNumber,
}: any) {
  const validators = [
    { title: "Mínimo de 8 caracteres", status: password.length >= passwordMinLength, enabled: validatePasswordLength },
    { title: "Um número", status: password.match(".*[0-9].*"), enabled: validateHasNumber },
    { title: "Uma letra maiúscula", status: password.match(".*[A-Z].*"), enabled: validateUpperCase },
    { title: "Uma letra minúscula", status: password.match(".*[a-z].*"), enabled: validateLowerCase },
  ];

  useEffect(() => {
    setIsPasswordValid(validators.filter((validator) => validator.enabled).every((validator) => validator.status));
  }, [password]);

  return (
    <section className={`password-validator-list-container`}>
      {validators.map((validator, index) => {
        if (validator.enabled) {
          return (
            <div
              key={validator.title}
              id={`text-signup-rule-${index}`}
              className={`password-validator-item ${validator.status ? "step-is-valid" : ""}`}
            >
              <CheckCircle style={{ fontSize: 16 }} />
              <span className={`validator-item-text`}>{validator.title}</span>
            </div>
          );
        }
      })}
    </section>
  );
}

PasswordValidatorSteps.propTypes = {
  password: string,
  setIsPasswordValid: func.isRequired,
  passwordMinLength: number,
  validatePasswordLength: bool,
  validateUpperCase: bool,
  validateLowerCase: bool,
  validateHasNumber: bool,
};

PasswordValidatorSteps.defaultProps = {
  password: "",
  passwordMinLength: 8,
  validatePasswordLength: true,
  validateUpperCase: true,
  validateLowerCase: true,
  validateHasNumber: false,
};

export default memo(PasswordValidatorSteps);
