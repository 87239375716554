import { SvgIcon } from "@mui/material";

function EyeOutlined(props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M13.6133 5.73341C12.2667 2.60675 9.73333 0.666748 7 0.666748C4.26667 0.666748 1.73333 2.60675 0.386666 5.73341C0.349958 5.81753 0.33101 5.90831 0.33101 6.00008C0.33101 6.09185 0.349958 6.18264 0.386666 6.26675C1.73333 9.39341 4.26667 11.3334 7 11.3334C9.73333 11.3334 12.2667 9.39341 13.6133 6.26675C13.65 6.18264 13.669 6.09185 13.669 6.00008C13.669 5.90831 13.65 5.81753 13.6133 5.73341ZM7 10.0001C4.88667 10.0001 2.88667 8.47341 1.73333 6.00008C2.88667 3.52675 4.88667 2.00008 7 2.00008C9.11333 2.00008 11.1133 3.52675 12.2667 6.00008C11.1133 8.47341 9.11333 10.0001 7 10.0001ZM7 3.33341C6.47258 3.33341 5.95701 3.48981 5.51848 3.78283C5.07995 4.07585 4.73815 4.49232 4.53632 4.97959C4.33449 5.46686 4.28168 6.00304 4.38457 6.52032C4.48747 7.0376 4.74144 7.51276 5.11438 7.8857C5.48732 8.25864 5.96248 8.51261 6.47976 8.61551C6.99704 8.7184 7.53322 8.66559 8.02049 8.46376C8.50776 8.26193 8.92424 7.92013 9.21725 7.4816C9.51027 7.04307 9.66667 6.5275 9.66667 6.00008C9.66667 5.29284 9.38572 4.61456 8.88562 4.11446C8.38552 3.61437 7.70724 3.33341 7 3.33341ZM7 7.33341C6.73629 7.33341 6.47851 7.25522 6.25924 7.10871C6.03997 6.9622 5.86908 6.75396 5.76816 6.51033C5.66724 6.26669 5.64084 5.9986 5.69229 5.73996C5.74373 5.48132 5.87072 5.24374 6.05719 5.05727C6.24366 4.8708 6.48124 4.74381 6.73988 4.69237C6.99852 4.64092 7.26661 4.66733 7.51024 4.76824C7.75388 4.86916 7.96212 5.04006 8.10863 5.25932C8.25513 5.47859 8.33333 5.73637 8.33333 6.00008C8.33333 6.3537 8.19286 6.69284 7.94281 6.94289C7.69276 7.19294 7.35362 7.33341 7 7.33341Z" />
    </SvgIcon>
  );
}

export default EyeOutlined;
