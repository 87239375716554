import { Skeleton } from "@mui/material";
import React from "react";
import "./styles.css";

function ChooseBizGroupSkeletonList() {
  const skeletonStyle = {
    transform: "none",
    minHeight: "48px",
  };

  const skeletonSx = {
    bgcolor: "grey.100",
  };

  return (
    <section id={`container-choosenetwork-loading`} className="business-group-list">
      <Skeleton width={`100%`} height={`48px`} style={skeletonStyle} sx={skeletonSx} />
      <Skeleton width={`100%`} height={`48px`} style={skeletonStyle} sx={skeletonSx} />
      <Skeleton width={`100%`} height={`48px`} style={skeletonStyle} sx={skeletonSx} />
      <Skeleton width={`100%`} height={`48px`} style={skeletonStyle} sx={skeletonSx} />
      <Skeleton width={`100%`} height={`48px`} style={skeletonStyle} sx={skeletonSx} />
      <Skeleton width={`100%`} height={`48px`} style={skeletonStyle} sx={skeletonSx} />
      <Skeleton width={`100%`} height={`48px`} style={skeletonStyle} sx={skeletonSx} />
      <Skeleton width={`100%`} height={`48px`} style={skeletonStyle} sx={skeletonSx} />
    </section>
  );
}

export default ChooseBizGroupSkeletonList;
