import { gql } from "@/graphql/apollo";

export const BUSINESS_OPERATING_AREAS_QUERY = gql`
  query BusinessOperatingAreas($id: Int, $name: String) {
    BusinessOperatingAreas(id: $id, name: $name) {
      id
      name
    }
  }
`;
