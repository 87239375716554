import { client, gql } from "../config";
import { CITIES_QUERY } from "@query/gql/city";

export async function getCities(variables: any) {
  const query = gql`
    query Cities($name: String) {
      Cities(name: $name) {
        ibge
        name
        uf
      }
    }
  `;
  const cities = await client.query({
    query,
    variables,
  });
  return await cities.data;
}

export async function getAllCities() {
  const cities = await client.query({
    query: CITIES_QUERY,
    fetchPolicy: "cache-first",
  });

  return await cities.data;
}
