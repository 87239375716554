import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { currency, generateArrayForChart } from "@services/utils";
import Skeleton from "react-loading-skeleton";
import "./styles.scss";
import LineChartApex from "@newcomponentes/Charts/LineChartApex";
import { Mouse } from "@newcomponentes/CustomIcons";
import Tooltip from "rc-tooltip";
import Collapse from "@mui/material/Collapse";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default function RankingCardGeneral({
  rank,
  title,
  subtitle,
  value,
  loading,
  loadingProductId,
  loadingPredictProductSales,
  rankingTitle,
  historyList = [],
  isShowCase = false,
  link,
  showCaseProducts = [],
}: any) {
  const rankColor = [1, 2, 3].includes(parseInt(rank)) ? "top3" : "last3";
  const [open, setOpen] = useState(false);

  const simpleContent = <span className="card-title-business">{title}</span>;
  const completeContent = (
    <div className="title-with-subtitle">
      <span className="card-title-business">
        <a href={link} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </span>
      <span className="subtitle">{subtitle}</span>
    </div>
  );

  const handleContentDict = (rankingTitle: string) => {
    const contentsDict: { [key: string]: JSX.Element } = {
      "Ranking de Shoppings": simpleContent,
      "Ranking de Lojas": completeContent,
      "Ranking de Setores": completeContent,
    };
    return contentsDict[rankingTitle] || completeContent;
  };

  if (loading || loadingProductId || loadingPredictProductSales) {
    return <Skeleton width={"100%"} height={95} />;
  }

  return (
    <div className="ranking-card-general anime-swing-in">
      <div className="main-card-container">
        <div className="card-content showcase-card-container" onClick={() => setOpen((old) => !old)}>
          <header className="card-header">
            <div className="card-title">
              <div className={`card-rank ${rankColor}`}>{`${rank}°`}</div>
              {handleContentDict(rankingTitle)}
            </div>

            {!isShowCase ? (
              <div className="card-value" style={{ gap: "0" }}>
                <span>{currency(value)}</span>
              </div>
            ) : (
              <Tooltip placement="right" overlay="Número de cliques">
                <div className="card-value" style={{ gap: "0" }}>
                  <span>{value}</span>
                  <Mouse style={{ fontSize: "16px" }} />
                </div>
              </Tooltip>
            )}
          </header>
          {!!historyList.length && (
            <span className="msg-text" style={{ width: "40%" }}>
              <LineChartApex
                name="Faturamento"
                isMonetary={true}
                data={generateArrayForChart(historyList)}
                labels={["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"]}
                width={"100%"}
                height={40}
              />
            </span>
          )}
          {!!showCaseProducts.length && (
            <IconButton aria-label="expand row" size="small">
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
          )}
        </div>
        {!!showCaseProducts.length && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <section className="showcase-products-container">
              {showCaseProducts.map((product: any, index: number) => {
                return (
                  <header key={product.id} className="card-header">
                    <div className="card-title">
                      <div className={`card-rank rank-color rank-color-${index + 1}`}>{`${index + 1}°`}</div>
                      <div className="title-with-subtitle">
                        <span className="card-title-business">
                          <a href={product.link} target="_blank" rel="noopener noreferrer">
                            {product.description}
                          </a>
                        </span>

                        {!!product.discountPercentage && (
                          <span className="subtitle">{`Desconto de ${+product.discountPercentage}%`}</span>
                        )}
                        <span className="subtitle">{title}</span>
                      </div>
                    </div>
                    <Tooltip placement="right" overlay="Número de cliques">
                      <div className="card-value" style={{ gap: "0" }}>
                        <span>{product.total_revenue}</span>
                        <Mouse style={{ fontSize: "16px" }} />
                      </div>
                    </Tooltip>
                  </header>
                );
              })}
            </section>
          </Collapse>
        )}
      </div>
    </div>
  );
}

RankingCardGeneral.propTypes = {
  rank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
