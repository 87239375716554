import React from "react";
import Chart from "react-apexcharts";
import { currency } from "@services/utils";

export default function LineChartApex({
  name = "Nome pra série de dados",
  data = [],
  labels = [],
  isMonetary,
  forecastDataPointsCount,
  ...props
}: any) {
  const state = {
    series: [
      {
        name,
        data,
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      chart: {
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 2,
          opacity: 0.2,
        },
        width: "100%",
        height: "100%",
        type: "line",
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      // markers: {
      //   size: 3,
      //   colors: ["#ffffff"],
      // },
      grid: {
        padding: {
          top: 10,
          bottom: 10,
          left: 10,
          right: 10,
        },
      },
      colors: ["#0095FF"],
      xaxis: {
        categories: labels,
      },
      yaxis: {
        show: false,
        labels: {
          formatter: function (value: any, index: any) {
            return `${isMonetary === true ? currency(value) : value}`;
          },
        },
      },
      tooltip: {
        enabled: true,
        fixed: {
          enabled: true,
          position: "topRight",
          offsetY: -65,
        },
      },
      forecastDataPoints: {
        count: forecastDataPointsCount,
      },
    },
  };

  return <Chart {...props} type="line" options={state.options} series={state.series} />;
}
