import React from "react";
import "./styles.scss";
import { string } from "prop-types";

function PasswordValidatorContainer({ className, ...rest }: any) {
  return <section className={`biud-password-validator-container ${className}`} {...rest} />;
}

PasswordValidatorContainer.propTypes = {
  className: string,
};

PasswordValidatorContainer.defaultProps = {
  className: "",
};

export default PasswordValidatorContainer;
