import { FetchPolicy } from "apollo-boost";
import { getCacheItemObj, setCacheItemObj } from "@services/auth";

type TKeys = {
  [key: string]: any;
};

const keys: TKeys = new Map<object, number>();
const itemCacheKeys: TKeys = getCacheItemObj() || new Map<object, number>();

/**
 * This function accepts a unique key and an expiration date. It returns "network-only" if the cache key is expired
 * or "cache-first" if the key is still valid for the given expiration time
 *
 * @param key - the unique name you want to give this expiration key
 * @param expirationMs
 * @param data)
 */

const ONE_MINUTE = 1000 * 60;

export enum CACHE_TIME {
  EXTRA_SHORT = ONE_MINUTE,
  SHORT = ONE_MINUTE * 3,
  NORMAL = ONE_MINUTE * 10,
  LONG = ONE_MINUTE * 30,
  EXTRA_LONG = ONE_MINUTE * 60,
}

export const getFetchPolicyForKey = (key: string, expirationMs: number, data: any, noCache = false): FetchPolicy => {
  if (noCache) delete keys[key];
  const lastFetchTimestamp = keys[key]?.expireAt;
  const oldBusinessId = keys[key]?.data?.businessId;
  const oldBusinessIds = JSON.stringify(keys[key]?.data?.businessIds);
  const diffFromNow = lastFetchTimestamp ? Date.now() - lastFetchTimestamp : Number.MAX_SAFE_INTEGER;
  let fetchPolicy: FetchPolicy = "cache-first";

  if (
    diffFromNow > expirationMs ||
    oldBusinessId !== data?.businessId ||
    oldBusinessIds !== JSON.stringify(data?.businessIds)
  ) {
    keys[key] = {
      expireAt: Date.now(),
      data: { businessId: data.businessId, businessIds: data.businessIds },
    };
    fetchPolicy = "network-only";
  }

  return fetchPolicy;
};

export const removeCacheKey = (key: string) => {
  return delete keys[key];
};

export const setCacheItem = (key: string, expirationMs: number, data: any) => {
  const lastFetchTimestamp = itemCacheKeys[key]?.expireAt;
  const oldBusinessId = itemCacheKeys[key]?.data?.businessId;
  const oldBusinessIds = JSON.stringify(itemCacheKeys[key]?.data?.businessIds);
  const diffFromNow = lastFetchTimestamp ? Date.now() - lastFetchTimestamp : Number.MAX_SAFE_INTEGER;
  let resp = false;

  if (
    diffFromNow > expirationMs ||
    oldBusinessId !== data?.businessId ||
    oldBusinessIds !== JSON.stringify(data?.businessIds)
  ) {
    itemCacheKeys[key] = {
      expireAt: Date.now(),
      expirationMs,
      data: { businessId: data.businessId, businessIds: data.businessIds },
    };
    resp = true;
  }

  setCacheItemObj(itemCacheKeys);
  return resp;
};

export const getIsThisItemCached = (key: string) => {
  const lastFetchTimestamp = itemCacheKeys[key]?.expireAt;
  const expirationMs = itemCacheKeys[key]?.expirationMs;
  const diffFromNow = lastFetchTimestamp ? Date.now() - lastFetchTimestamp : Number.MAX_SAFE_INTEGER;

  return diffFromNow < expirationMs;
};

export const getCacheItemTimeRemaining = (key: string, stringReturn = false) => {
  const lastFetchTimestamp = itemCacheKeys[key]?.expireAt;
  const expirationMs = itemCacheKeys[key]?.expirationMs;
  const diffFromNow = lastFetchTimestamp ? Date.now() - lastFetchTimestamp : Number.MAX_SAFE_INTEGER;
  const diffValue = diffFromNow < expirationMs ? Math.ceil((expirationMs - diffFromNow) / 1000 / 60) : 0;
  if (stringReturn) return `${diffValue} ${diffValue === 1 ? "minuto" : "minutos"}`;
  return diffValue;
};
