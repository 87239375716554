const INITIAL_STATE = {
  data: {
    rfvInfo: {
      total: 0,
      status: "",
      classifications: {},
    },
    rfvInfoLoading: true,
  },
};

export type TNetworkCustomer = typeof INITIAL_STATE;

export const NETWORK_CUSTOMER = {
  RFV_INFO: "network-customer/rfv-info",
  RFV_INFO_LOADING: "network-customer/rfv-info-loading",
  CLEAR: "network-customer/clear",
};

export default function NetworkCustomerReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case NETWORK_CUSTOMER.RFV_INFO:
      return { ...state, data: { ...state.data, rfvInfo: action.payload } };
    case NETWORK_CUSTOMER.RFV_INFO_LOADING:
      return { ...state, data: { ...state.data, rfvInfoLoading: action.payload } };
    case NETWORK_CUSTOMER.CLEAR:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
