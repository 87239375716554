import React from "react";
import { imgLoading } from "@imgs";
import { removeAccent } from "@services/utils";
import { CircularProgress } from "@mui/material";
import { isComercial } from "@services/auth";
import ChooseBizGroupSkeletonList from "./Skeleton";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface IChooseBizGroupModalListProps {
  search?: string;
  loading: boolean;
  userBusinessGroups: object[];
  logos: any;
  handleSelectBusinessGroup?: (businessGroup: any) => void;
}

function ChooseBizGroupModalList({
  search = "",
  loading,
  userBusinessGroups,
  logos,
  handleSelectBusinessGroup = () => "",
}: IChooseBizGroupModalListProps) {
  const filteredBusiness2 =
    !search || search.length === 0
      ? userBusinessGroups
      : userBusinessGroups.filter((ubg: any) =>
          removeAccent(ubg.name).toUpperCase().includes(removeAccent(search.toUpperCase()))
        );

  const filteredBusiness = isComercial()
    ? filteredBusiness2?.map((group: any) => {
        if (group.id !== 2188 && group.id !== 1463) return group;
        if (group.id === 1463) {
          const hambBiud = { ...group };
          hambBiud.logo = null;
          hambBiud.name = "Rede Hamburgueria BIUD";
          return hambBiud;
        }
        const drogariaBiud = { ...group };
        drogariaBiud.logo = null;
        drogariaBiud.name = "Rede Drogaria BIUD";
        return drogariaBiud;
      })
    : filteredBusiness2;

  return (
    <div id="container-choosenetwork-networklist" className="business-group-list">
      {loading ? (
        <ChooseBizGroupSkeletonList />
      ) : (
        filteredBusiness?.map((businessGroup: any) => (
          <div
            key={`business-group-item-${businessGroup.id}`}
            className="business-group-item"
            id={`btn-choosenetwork-select`}
            onClick={() => handleSelectBusinessGroup(businessGroup)}
          >
            {!logos[businessGroup.id] ? (
              <CircularProgress size={12} style={{ margin: "0 10px" }} />
            ) : (
              <LazyLoadImage
                src={logos[businessGroup.id] || imgLoading}
                alt={`Logomarca da rede ${businessGroup.name}`}
                className="network-logo"
              />
            )}

            <div className="business-group-item-info">
              <div className="bold" id={`text-choosenetwork-name`}>
                {businessGroup.name}
              </div>
              <div className="business-qnt">
                <span className="info" id={`text-choosenetwork-bizqnt`}>
                  {businessGroup.businesses.length || 0}
                </span>
                <span className="info">{businessGroup.businesses.length == 1 ? "empresa" : "empresas"}</span>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default ChooseBizGroupModalList;
